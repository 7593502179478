import { useCookieValue } from '@react-hookz/web/useCookieValue/index.js';


export function useCookieValueJSON(key, defaultValue) {
  const [ _state, _setState ] = useCookieValue(`flopus_${key}`, {
    domain: process.env.NEXT_PUBLIC_DOMAIN,
    expires: 365,
    initializeWithValue: true
  });

  const state = typeof _state === 'string' ? JSON.parse(_state) : defaultValue;
  
  return [
    state,
    (value) => _setState(JSON.stringify(value))
  ];
}
