import moment from 'moment';

export const getInitialShippingDate = (orderData, localOrderData) => {
  const savedISODate = orderData?.selectedShippingDate || localOrderData?.selectedShippingDate;

  if (savedISODate && moment(savedISODate).isAfter(moment().add(1, 'hours'))) {
    return moment(savedISODate);
  }

  console.log('no saved shipping date found');

  return moment().add(6, 'hours').set('minutes', 0).set('seconds', 0);
}
