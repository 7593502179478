export const resizeTemplatedImage = (url, width, height) => {
  if (!url) {
    return null;
  }

  // console.log(url.replace(/{options}/, `rs:fill-down:${width}:${height}:1`))
  return url.replace(/{options}/, `rs:fill-down:${width}:${height}:1`);
}

export const getRawImage = (url) => {
  return url.replace(/{options}\//, '');
}

export const getPreviewFromImageList = (images, width, height) => {
  const preview = images.find(({ type }) => (type === 'image'));

  return preview ? resizeTemplatedImage(preview.url, width, height) : null;
}
