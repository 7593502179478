import { ChevronRightIcon } from '@heroicons/react/20/solid';
import Link from 'next/link';

export function Breadcrumbs({ path, className }) {
  return (
    <nav className={className} aria-label="Навигация">
      <ol role="list" className="flex items-center space-x-2 text-sm md:text-base">
        {
          path.map((item, index) => {
            const isLastItem = index === path.length - 1;
            const Wrapper = ({ href, children }) => href
              ? <Link prefetch={false} href={href} className="flex items-center group py-2 font-medium text-gray-900">{ children }</Link>
              : <>{ children }</>;

            return (
              <li key={index}>
                <Wrapper href={item.href}>
                  <span className="mr-2 group-hover:text-blue-600">
                    { item.title }
                  </span>
                  {
                    !isLastItem &&
                      <ChevronRightIcon className="flex-shrink-0 h-5 w-5 text-gray-600" aria-hidden="true" />
                  }
                </Wrapper>
              </li>
            )
          })
        }
      </ol>
    </nav>
  )
}
