import { ClockIcon, GiftIcon, MapPinIcon, UserIcon } from '@heroicons/react/20/solid';
import { ArrowRightIcon, ChevronRightIcon } from '@heroicons/react/24/outline';
import {
  CartAddress,
  CartMobileBottomBar,
  CartMobileComment,
  CartMobileDrawerButton,
  CartPriceSummary,
  CartProductList
} from 'components/cart';
import { BlockDivider, Button, Emoji } from 'components/common';
import { MobileCartContext } from 'context';
import { useCartData } from 'hooks';
import { useCart } from 'query/orders';
import { useContext, useState } from 'react';
import { formatShippingDate, getFormattedPhoneNumber } from 'utils';

export const CartDetailsScreen = () => {
  const { data, isLoading: isCartLoading } = useCart();
  const { setCurrentScreen } = useContext(MobileCartContext);
  const [ localCartData, setLocalCartData, setLocalCartDataKey ] = useCartData();

  const [ isDateModalOpen, setDateModalOpen ] = useState(false);
  const [ isAddressModalOpen, setAddressModalOpen ] = useState(false);
  const [ isContactsModalOpen, setContactsModalOpen ] = useState(false);
  const [ isReceiverModalOpen, setReceiverModalOpen ] = useState(false);
  const [ isPostCardModalOpen, setPostCardModalOpen ] = useState(false);
  const { title: addressTitle, subtitle: addressSubtitle } = CartAddress(data);

  const isDragDisabled = true;
  const postCardText = data.metadata.find(({ key }) => key === 'postcard_text')?.value;

  return (
    <div>
      <div className="px-4 pt-4">
        <CartMobileDrawerButton
          icon={<MapPinIcon className="w-6 h-6"/>}
          title={addressTitle}
          problems={!data.address?.street}
          subtitle={addressSubtitle}
          onClick={() => setAddressModalOpen(true)}
        />
        <CartMobileDrawerButton
          icon={<ClockIcon className="w-6 h-6"/>}
          problems={!data.selectedShippingDate}
          title={
            data.selectedShippingDate
              ? formatShippingDate(data.selectedShippingDate)
              : 'Дата доставки'
          }
          // problems={!data.selectedShippingDate}
          onClick={() => setDateModalOpen(true)}
        />
        <div className="mt-2 border-t border-gray-100"/>
        <CartMobileDrawerButton
          icon={<UserIcon className="w-6 h-6"/>}
          problems={!data.createdBy?.fullName || !data.createdBy?.phoneNumber}
          title={
            data.createdBy?.fullName
              ? `Заказывает ${data.createdBy.fullName}`
              : 'Ваши контакты'
          }
          subtitle={
            data.createdBy?.phoneNumber
              ? getFormattedPhoneNumber(data.createdBy?.phoneNumber)
              : (data.createdBy?.fullName && <span className="text-orange-600">Уточните свой номер телефона</span>)
          }
          onClick={() => setContactsModalOpen(true)}
        />
        {
          !localCartData.isNotGift &&
          <CartMobileDrawerButton
            icon={<GiftIcon className="w-6 h-6"/>}
            title={data.receiver?.name ? `Получит ${data.receiver?.name}` : 'Контакты получателя'}
            subtitle={
              (!data.receiver?.name && !data.receiver?.phoneNumber)
                ? null
                : (data.receiver?.name && !data.receiver?.phoneNumber)
                  ? <>Уточните номер телефона <Emoji size={3}>1f64f</Emoji></>
                  : getFormattedPhoneNumber(data.receiver?.phoneNumber)
            }
            onClick={() => setReceiverModalOpen(true)}
          />
        }
      </div>

      <BlockDivider/>
      <div className="px-4 -mt-2">
        <h3 className="mb-4">корзина</h3>
        <CartProductList previewClassName="w-12 h-12"/>
        <div className="mt-6">
          <CartPriceSummary/>
        </div>
      </div>
      <BlockDivider/>
      <div
        onClick={() => setPostCardModalOpen(true)}
        className="bg-white cursor-pointer px-4 flex shrink-0 items-center justify-between gap-3"
      >
        <div className="w-12 block shrink-1 flex justify-center">
          <img src={'/postcard.png'} className="h-10" alt=""/>
        </div>
        {
          postCardText &&
          <div className="flex-1 justify-between font-medium text-sm truncate">
            В открытке напишем <br/><span className="text-gray-500">«{ postCardText }»</span>
          </div>
        }
        {
          !postCardText &&
          <div className="flex-1 justify-between  font-medium truncate">
            { localCartData?.postCardText?.length ? localCartData.postCardText : <>Добавить открытку в заказ</> }
          </div>
        }
        <div className="ml-2">
          <ChevronRightIcon className="h-5 w-5 opacity-40 group-hover:opacity-70"/>
        </div>
      </div>
      <BlockDivider/>
      <div>
        <div className="px-4 -mt-2 mb-4">
          <h3>примечания</h3>
        </div>
        <CartMobileComment/>
      </div>
      <div className="h-20"/>
      <CartMobileBottomBar>
        <div className="p-4 flex flex-col">
          <Button
            color="blue"
            className="gap-2 flex"
            onClick={() => setCurrentScreen('checkout')}
          >
            Выбрать адрес и оформить <ArrowRightIcon className="w-5 h-5"/>
          </Button>
        </div>
      </CartMobileBottomBar>
    </div>
  )
}
