import { MagnifyingGlassIcon, XMarkIcon } from '@heroicons/react/24/outline';
import cn from 'classnames';
import { useFormik } from 'formik';
import { useRouter } from 'next/router';
import { useRef } from 'react';
import * as yup from 'yup';


export function SearchInput({ value, onChange, className, placeholder = 'Найти', inputClassName, ...rest }) {
  const ref = useRef(null);
  const router = useRouter();
  const formik = useFormik({
    validationSchema: yup.object().shape({
      query: yup.string().trim().length(3).required()
    }),
    initialValues: {
      query: ''
    },
    onSubmit: ({ query }) => {
      ref.current.blur();
      router.push(`/search?query=${query}`);
    },
  });

  return (
    <form onSubmit={formik.handleSubmit} className={cn(className, 'relative flex items-center justify-center')}>
      <MagnifyingGlassIcon className="absolute left-3 h-6 w-6 text-black/60"/>
      <input
        ref={ref}
        name="query"
        autoComplete="off"
        value={value}
        onChange={(e) => {
          onChange(e.target.value)
          formik.handleChange(e);
        }}
        { ...rest }
        className={cn(inputClassName, 'h-11 rounded-2xl text-xl w-full pl-12 shadow-none border-0  focus:placeholder:text-black/30')}
        placeholder={placeholder}
      />
      {
        (value?.length > 0) &&
            <div
              className="transform-opacity group absolute bg-gray-100 rounded-full w-8 h-8 right-2 flex items-center justify-center cursor-pointer"
              onClick={() => onChange('')}
            >
              <XMarkIcon className="absolute w-5 h-5 text-black/40 group-hover:text-black/80"/>
            </div>
      }
    </form>
  )
}
