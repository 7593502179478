import { Switch } from 'components/common';
import { ModalWrapper, PostCardModal } from 'components/modals/index';
import { useCart } from 'query/orders';
import { useState } from 'react';

export function PostCardModalDesktopButton({ onChange }) {
  const { data, isLoading, isError } = useCart();
  const [ isPostCardModalOpen, setPostCardModalOpen ] = useState(false);
  const postCardText = data?.metadata?.find(({ key }) => key === 'postcard_text')?.value;

  if (isLoading) {
    return <p> </p>
  }

  return (
    <>
      <ModalWrapper
        sheetClass="bg-header"
        isOpen={isPostCardModalOpen}
        setIsOpen={setPostCardModalOpen}
        modalStyle={{ maxWidth: '550px' }}
        onDismiss={() => setPostCardModalOpen(false)}
      >
        <PostCardModal
          initialValue={postCardText}
          onTextSaved={(value) => onChange(value)}
          setIsOpen={setPostCardModalOpen}
        />
      </ModalWrapper>

      <div onClick={() => setPostCardModalOpen(true)} className="bg-white cursor-pointer group md:mt-14 rounded-2xl p-8 bg-gradient-to-tr flex justify-between">
        <div className="flex w-full justify-between gap-5">

          <div className="flex justify-between flex-col">
            <h3 className="-mt-1">добавить открытку</h3>
            <div className="flex items-center gap-3 my-2.5">
              <Switch enabled={postCardText?.length > 0}/>
              <p>бесплатно</p>
            </div>

            <p className="text-gray-700">
              { postCardText?.length ? `напишем «${postCardText}»` : 'подпишем от руки и передадим вместе с заказом' }
            </p>
          </div>
          <img src={'/postcard.png'} className="h-11 sm:h-16" alt=""/>
        </div>
      </div>
    </>
  )
}
