import Link from 'next/link';

import styles from './Logo.module.css';

export function Logo({ style = {} }) {
  return (
    <Link
      aria-label="Главная"
      className={styles.logo}
      style={style}
      prefetch={false}
      href="/"
    />
  )
}
