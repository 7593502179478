import React from 'react';
import { formatNumber, getNoun } from 'utils';

export default function BonusValue({ value }) {
  const convertedValue = (value / 100) | 0;

  return (
    <div className="mt-1 rounded-full flex text-sm flex-row items-center gap-1">
      <img src={'/coin.svg'} className="w-4 h-4"/>
      <span style={{ color: '#896f1d' }}>
        { formatNumber(convertedValue) } { getNoun(convertedValue, 'бонус', 'бонуса', 'бонусов') } на счёт
      </span>
    </div>
  )
}
