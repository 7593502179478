import axios from 'axios';
import clone from 'clone-deep';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { OrdersService } from 'services/api';
import * as cookies from 'utils/cookies';


const Orders = new OrdersService();

export const useOrder = (id) => useQuery(
  [ 'order', id ],
  () => id ? Orders.getById(id) : null
);

export const useOrderPaymentStatus = (id) => useQuery(
  [ 'order-payment-status', id ],
  () => id ? Orders.getPaymentStatus(id) : null,
  {
    refetchOnWindowFocus: true,
    refetchIntervalInBackground: false,
    refetchInterval: 5000,
    refetchOnMount: true
  }
);

export const useOrderStatuses = (ids) => useQuery(
  [ 'active-order-statuses' ],
  () => ids ? Orders.getOrderStatuses(ids) : [],
  {
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  }
);

export const useUpdatePaymentMethod = () => {
  const queryClient = useQueryClient();

  return useMutation(({ uuid, paymentMethod }) => {
    return axios.post(`/api/orders/${uuid}/changePaymentMethod`, {
      paymentMethod
    });
  }, {
    onSuccess: async () => {
      await queryClient.invalidateQueries('order');
    }
  });
};

export const useSubmitOrder = () => {
  const queryClient = useQueryClient();

  return useMutation(({ uuid, ... rest }) => {
    console.log('submit order', rest)
    return axios.post(`/api/orders/${uuid}/submit`, rest);
  }, {
    onSuccess: async ({ data }) => {
      const activeOrders = cookies.get('active_orders', []);

      cookies.set('active_orders', [ ...activeOrders, data.id ]);
      await queryClient.invalidateQueries('cart');
    }
  });
}

export const useUpdateCart = () => {
  const queryClient = useQueryClient();

  return useMutation(({ uuid, ... params }) => Orders.update(uuid, params), {
    onSuccess: async (result) => {
      queryClient.setQueryData('cart', () => result);
    }
  });
}

export const useUpdatePostCard = () => {
  const queryClient = useQueryClient();

  return useMutation(({ uuid, text }) => axios.post(`/api/orders/${uuid}/updatePostCard`, { text }), {
    onSuccess: async () => {
      await queryClient.invalidateQueries('cart');
    }
  });
}


export const useCart = () => useQuery(
  [ 'cart' ],
  async () => {
    const cartId = cookies.get('flopus_cart');

    if (cartId) {
      try {
        const result = await Orders.getById(cartId);

        if (result?.status !== 'draft') {
          cookies.remove('flopus_cart', {
            domain: process.env.NEXT_PUBLIC_DOMAIN,
            expires: 0
          });
        } else {
          return result;
        }
      } catch (e) {
        if (e.status === 404) {
          cookies.remove('flopus_cart', {
            domain: process.env.NEXT_PUBLIC_DOMAIN,
            expires: 0
          });
        }
      }
    }

    return null;
  }
);

export const useAddProduct = ({ onSuccess } = {}) => {
  const queryClient = useQueryClient();

  return useMutation(({ productId, qty }) => Orders.addToCart(productId, qty), {
    onMutate: async ({ productId, qty }) => {
      await queryClient.cancelQueries('cart');

      const previousState = queryClient.getQueryData('cart');

      if (previousState) {
        const optimisticState = clone(previousState);
        const existingProduct = optimisticState.items.find(({ product }) => product.id === productId);

        if (existingProduct) {
          existingProduct.qty = qty;
        } else {
          optimisticState.items.push({
            optimistic: true,
            uuid: (+new Date()).toString(),
            qty,
            product: {
              id: productId
            }
          });
        }

        queryClient.setQueryData('cart', () => optimisticState);
      }

      return { previousState };
    },
    onError: (err, newState, context) => {
      queryClient.setQueryData('cart', () => context.previousState);
    },
    onSuccess: (result) => {
      if (onSuccess) {
        onSuccess();
      }

      queryClient.setQueryData('cart', () => result);
    }
  })
}

export const useEditProduct = () => {
  const queryClient = useQueryClient();

  return useMutation(({ itemUUID, qty }) => Orders.editCartItem(itemUUID, qty), {
    onMutate: async ({ itemUUID, qty }) => {
      await queryClient.cancelQueries('cart');

      const previousState = queryClient.getQueryData('cart');

      if (previousState) {
        const optimisticState = clone(previousState);
        const idx = optimisticState.items.findIndex(({ uuid }) => uuid === itemUUID);

        if (idx !== -1) {
          if (qty < 1) {
            optimisticState.items.splice(idx, 1);
          } else {
            optimisticState.items[idx].qty = qty;
          }

          queryClient.setQueryData('cart', () => optimisticState);
        }
      }

      return { previousState };
    },
    onError: (err, newState, context) => {
      queryClient.setQueryData('cart', () => context.previousState);
    },
    onSuccess: (result) => {
      queryClient.setQueryData('cart', () => result);
    }
  })
}
