import { formatDate, formatPrice } from 'utils';

export const DolyameSchedule = ({ price }) => {
  const pricePart = Math.round(price / 4);
  const periods = [
    +new Date() + 86400000 * 7,
    +new Date() + 86400000 * 14,
    +new Date() + 86400000 * 21,
  ];

  return (
    <div className="bg-gray-50 rounded-xl p-4 flex gap-3">
      <div className="flex-1">
        <p className="text-xs lg:text-sm">Сегодня</p>
        <p className="font-medium">{formatPrice(pricePart)} руб</p>
        <div className="mt-2 h-2 rounded-full bg-blue-600"/>
      </div>
      {
        periods.map((date, index) => (
          <div key={`date_${index}`} className="flex-1 text-gray-500">
            <p className="text-xs lg:text-sm">{ formatDate(date) }</p>
            <p>{formatPrice(pricePart)} руб</p>
            <div className="mt-2 h-2 rounded-full bg-gray-200"/>
          </div>
        ))
      }
    </div>
  )
};
