import { Button, Input, LocationSuggester } from 'components/common';
import { MODALS } from 'components/modals/constants';
import { useFormik } from 'formik';
import { useCart, useUpdateCart } from 'query/orders';
import { useState } from 'react';
import { getAddressString } from 'utils';
import * as yup from 'yup';


export const IDENTIFIER = MODALS.DELIVERY_ADDRESS;

export function DeliveryAddressModal({ onChange }) {
  const { isLoading, data } = useCart();
  const { mutateAsync, isSuccess, isLoading: isUpdating } = useUpdateCart();
  const schema = yup.object({
    location: yup.object({ lat: yup.number(), lng: yup.number() }).default(data.location).nullable(),
    city: yup.string().default(data.address?.city || ''),
    street: yup.string().default(data.address?.street || ''),
    streetType: yup.string().default(data.address?.streetType || ''),
    houseNumber: yup.string().default(data.address?.houseNumber || ''),
    houseType: yup.string().default(data.address?.houseType || ''),
    entrance: yup.string().default(data.address?.entrance || ''),
    floor: yup.string().default(data.address?.floor || ''),
    apartment: yup.string().default(data.address?.apartment || ''),
    apartmentType: yup.string().default(data.address?.apartmentType || ''),
    deliveryNotes: yup.string().default(data.deliveryNotes || ''),
  });


  const form = useFormik({
    initialValues: schema.cast(),
    validationSchema: schema,
    onSubmit: async (values) => {
      console.info('[DeliveryAdressModal]:', values);

      await mutateAsync({
        uuid: data.uuid,
        ... values,
      });

      if (onChange) {
        onChange();
      }
    }
  });

  const [ input, setInput ] = useState(getAddressString(data.address, false, true) || '');
  const onSelectAddress = (result) => {
    if (!result?.location) return;

    const schemaKeys = Object.keys(schema.cast());

    console.log('query =', input);
    console.log('resolved query', getAddressString(result, false, true));
    console.log('selected address in address modal', result);

    for (const [ key, value ] of Object.entries(result)) {
      if (value && schemaKeys.includes(key)) {
        form.setFieldValue(key, value);
      }
    }

    setInput(getAddressString(result, false, true) || '');
  }

  return (
    <form onSubmit={form.handleSubmit} className="flex flex-col">
      <h3 className="mb-3">Адрес доставки</h3>
      <div className="gap-3 md:gap-4 grid grid-cols-12">
        <div className="col-span-12">
          <LocationSuggester
            placeholder="Улица и номер дома"
            autoFocus={false}
            withoutCity={true}
            value={input}
            onChange={(e) => setInput(e.target.value)}
            name="address"
            onSelect={(result) => onSelectAddress(result)}
          />
          {/*<ErrorMessage name="address" component={FormError}/>*/}
        </div>

        <Input
          className="col-span-6 md:col-span-4"
          inputMode="numeric"
          placeholder="Подъезд"
          name="entrance"
          value={form.values.entrance}
          onChange={form.handleChange}
        />
        <Input
          className="col-span-6 md:col-span-4"
          inputMode="numeric"
          placeholder="Этаж"
          name="floor"
          value={form.values.floor}
          onChange={form.handleChange}
        />

        <div className="col-span-6 md:col-span-4">
          <Input
            placeholder="Кв/офис"
            inputMode="numeric"
            name="apartment"
            value={form.values.apartment}
            onChange={form.handleChange}
          />
          {/*<ErrorMessage name="flat" component={FormError}/>*/}
        </div>

        <Input
          className="col-span-12"
          placeholder="Комментарий курьеру"
          name="deliveryNotes"
          value={form.values.deliveryNotes}
          onChange={form.handleChange}
        />

        <Button
          color="blue"
          className="col-span-12"
          disabled={isLoading || isUpdating || !form.values.street}
          type="submit"
        >
          { form.values.street ? 'Сохранить' : 'Выберите улицу и номер дома' }
        </Button>
      </div>
    </form>
  )
}
