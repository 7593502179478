import { useWindowSize } from '@react-hookz/web';
import cn from 'classnames';
import { Button, ProductPrice, QtyModificator } from 'components/common';
import BonusValue from 'components/common/BonusValue/BonusValue';
import { ResponsiveImage } from 'components/common/ResponsiveImage/ResponsiveImage';
import { DolyameProductPrice } from 'components/dolyame';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useAddProduct, useCart, useEditProduct } from 'query/orders';
import React, { forwardRef, useState } from 'react';
import { ProductsService } from 'services/api';
import { useProductModal } from 'store';
import { isEligibleForDolyame } from 'utils';
import { reachGoal } from 'utils/metrika';

import styles from './ProductCard.module.css';


const Products = new ProductsService();

const ProductCard = forwardRef(({ className, product, lazy, size = 600, style, onClick, slug }, ref) => {
  const isDrawerLoading = useProductModal(state => state.isLoading);
  const setDrawerLoading = useProductModal(state => state.setLoading);
  const pushToDrawerStack = useProductModal(state => state.pushToStack);

  const router = useRouter();
  const [ currentPreview, setCurrentPreview ] = useState(0);
  const { mutate: addProductMutation, isLoading: isAddFetching } = useAddProduct();
  const { mutate: editProductMutation, isLoading: isEditFetching } = useEditProduct();
  const { isLoading, data: cartData } = useCart();

  const productInCart = !isLoading && cartData?.items.find(item => item.product.id === product.id);
  const images = product.images.filter(({ type }) => type === 'image');

  const { width = 1024 } = useWindowSize();
  const isMobile = width <= 768;

  const addToCart = (e) => {
    e.preventDefault();

    reachGoal('cart_added_product', {
      product_id: product.id,
      title: product.title,
      source: 'product_card'
    });

    addProductMutation({
      productId: product.id,
      qty: 1
    });
  }

  const editCartItem = (newQty) => {
    if (newQty === 0) {
      reachGoal('cart_removed_product', {
        product_id: product.id,
        title: product.title,
        source: 'product_card'
      });
    }

    editProductMutation({
      itemUUID: productInCart.uuid,
      qty: newQty
    });
  }

  const href = (slug.startsWith('/') ? '' : '/') + slug;

  return (
    <Link
      ref={ref}
      style={style}
      className={ cn(className, styles.card, 'group') }
      prefetch={false}
      aria-label={product.title}
      href={href}
      {
        ... !isMobile && onClick && {
          onClick
        }
      }
      {
        ... isMobile && {
          // as: href,
          onClick: (e) => {
            console.log(e)
            e.preventDefault();

            // TODO: если открыть в подборке то будет ошибка
            if (!isDrawerLoading) {
              setDrawerLoading(true);

              Products.getById(product.id)
                .then(data => {
                  console.log({
                    product: data,
                    returnUrl: router.asPath
                  });

                  pushToDrawerStack({
                    product: data,
                    returnUrl: router.asPath
                  });
                });

              window.history.pushState({ url: href },product.title, href);
              // router.push(href, undefined, { shallow: true });

              if (onClick) {
                onClick();
              }
            }
          },
        }
      }
    >
      <div className={ styles.previewContainer }>
        <ResponsiveImage
          src={images[currentPreview]?.url || 'https://img.flopus.ru/{options}/czM6Ly9mbG9wdXMvcHJvZHVjdC1nYXAuanBn'}
          breakpoints={{
            500: 400,
            768: 300,
            1024: 400,
            1200: 520
          }}
          lazy={lazy}
          defaultSize={300}
          alt={product.title}
          className="top-0 absolute bg-gray-100"
        />
        {
          (images.length) > 1 &&
            <div className="flex z-10">
              {
                images.slice(1, images.length).map((_, index) => {
                  return (
                    <div
                      onMouseEnter={() => setCurrentPreview(index + 1)}
                      onMouseLeave={() => setCurrentPreview(0)}
                      key={ index }
                      style={ {
                        width: ((100 / (images.length - 1)) - 0.1) + '%'
                      } }
                    />
                  );
                })
              }
            </div>
        }
        {
          (currentPreview > 0 && images.length > 2) &&
              <div className="absolute bottom-0 flex justify-center p-5 gap-2">
                {
                  images.slice(1, images.length).map((_, index) => (
                    <div
                      key={index}
                      className={cn(currentPreview - 1 === index ? 'opacity-60' : 'opacity-30', 'border w-2 h-2 bg-white rounded-full shadow-lg')}
                    />
                  ))
                }
              </div>
        }
      </div>

      <div className="relative p-2 h-full flex flex-col">
        <div className={ cn(styles.addToCartBtn, productInCart ? 'opacity-100' : 'opacity-0 group-hover:opacity-100') }>
          {/* Корзина на десктопе */}
          {
            !product.outOfStock &&
              <>
                {
                  productInCart
                    ? (
                      <QtyModificator
                        className="shadow-md shadow-gray-200/60 h-11"
                        currentQty={productInCart.qty}
                        disabled={isEditFetching || isAddFetching}
                        onQtyChanged={newQty => editCartItem(newQty)}
                      />
                    ) : (
                      <Button
                        className={'opacity-0 group-hover:opacity-100'}
                        aria-label="Добавить в корзину"
                        onClick={(e) => addToCart(e)}
                        color="shadow-white"
                        disabled={isEditFetching || isAddFetching}
                      >
                        В корзину
                      </Button>
                    )
                }
              </>
          }
        </div>
        {/* Заголовок */}
        <h3 className={styles.title}>{ product.title }</h3>
        { product.outOfStock && <div className="text-gray-600 h-full flex">Скоро будет</div> }
        {/* Корзина на мобиле */}
        {
          !product.outOfStock &&
            <div className="relative">
              <div className={styles.priceWrap}>
                <ProductPrice className="text-2xl" product={product}/>
                {
                  isEligibleForDolyame(product) &&
                  <DolyameProductPrice initialPrice={product.price}/>
                }
              </div>
              {
                product.bonuses > 0 &&
                <BonusValue value={product.bonuses}/>
              }
              <div className={styles.cartBtnWrap}>
                {
                  !productInCart &&
                  <Button
                    aria-label="Добавить в корзину"
                    onClick={(e) => {
                      addToCart(e);
                      e.stopPropagation();
                    }}
                    disabled={isEditFetching || isAddFetching}
                    color="gray"
                    size="sm"
                    className="w-full h-10"
                  >
                    <ProductPrice className="text-base" product={product}/>
                  </Button>
                }
                {
                  productInCart &&
                  <div className={styles.qtyWrap}>
                    {/*<Price price={product.price}/>*/}
                    <QtyModificator
                      size="sm"
                      className="w-full h-10"
                      currentQty={productInCart.qty}
                      disabled={isEditFetching || isAddFetching}
                      onQtyChanged={newQty => editCartItem(newQty)}
                    />
                  </div>
                }
              </div>
            </div>
        }
      </div>
    </Link>
  )
});

ProductCard.displayName = 'ProductCard';

export {
  ProductCard
};
