import cn from 'classnames';

export const DeliveryOption = ({ active, children, onClick }) => {
  return (
    <div
      onClick={onClick}
      className={
        cn(
          'p-3 rounded-2xl',
          active && 'bg-gray-100'
        )
      }
    >
      { children }
    </div>
  )
}
