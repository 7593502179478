import cn from 'classnames';
import React from 'react';
import { getNoun } from 'utils';

import styles from './ProductComponents.module.css';


const Component = ({ ...props }) => {
  const qty = (props.qty > 1 && props.isQtyVisible)
    ? `${ props.qty } `
    : '';

  return (
    <span>
      { qty }
      {/*<Link href={'/asd'}>*/}
      <span>
        {
          props.isQtyVisible
            ? getNoun(props.qty, ... props.titlePlurals)
            : (props.qty > 1) ? props.titlePlurals[3] : props.titlePlurals[0]
        }
      </span>
    </span>
  );
}

export const BriefProductComponents = ({ components }) => {
  return (
    <div className="inline-block leading-7">
      {
        components.sort((a, b) => b.qty - a.qty)
          .map(component => <Component key={component.id} {...component}/>)
          .reduce((acc, component, index, initialValue) => {
            return [
              acc,
              (initialValue.length === index + 1) ? ' и ' : ', ',
              component
            ]
          })
      }
    </div>
  );
}

export const DetailedProductComponent = ({ className, components }) => {
  return (
    <div className={cn(className, 'flex max-w-lg flex-col gap-3')}>
      {
        components.sort((a, b) => b.qty - a.qty).map(component => (
          <div className="flex gap-3" key={component.id}>
            <span className={cn(styles.col)}>{ component.qty > 1 ? component.titlePlurals[3] : component.titlePlurals[0] }</span>
            <span className={cn(styles.dots)}/>
            <span className={cn('pr-1 overflow-hidden', styles.col)}>{ component.qty }</span>
          </div>
        ))
      }
    </div>
  )
}
