import { useCookieValue } from '@react-hookz/web/useCookieValue/index.js';

export const useBookmarks = () => {
  const [ _bookmarks = '[]', _setBookmarks ] = useCookieValue('flopus_bookmarks', {
    domain: process.env.NEXT_PUBLIC_DOMAIN,
    expires: 365,
    // sameSite: 'strict',
    // path: '',
    initializeWithValue: true
  });

  if (!_bookmarks) {
    _setBookmarks('[]');
  }

  const bookmarks = JSON.parse(_bookmarks);

  return [
    bookmarks,
    (value) => _setBookmarks(JSON.stringify(value))
  ];
}
