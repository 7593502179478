'use client';

import { useCookieValueJSON } from 'hooks';
import { useOrderStatuses } from 'query/orders';
import { useEffect } from 'react';

import { ActiveOrderCard } from '../ActiveOrderCard/ActiveOrderCard';
import styles from '../ActiveOrderCard/ActiveOrderCard.module.css';


export function ActiveOrders() {
  const [ activeOrders, setActiveOrders ] = useCookieValueJSON('active_orders', []);
  const { data: orders, isLoading } = useOrderStatuses(activeOrders);

  useEffect(() => {
    if (orders?.length) {
      setActiveOrders(orders.map(({ uuid }) => uuid));
    }
  }, [ orders, setActiveOrders ]);

  if (!activeOrders?.length) {
    return null;
  }

  if (isLoading || !orders.length) {
    return (
      <div className="-my-4">
        <div className="inline-block w-full whitespace-nowrap p-4 pr-1 overflow-scroll no-scrollbar">
          {
            [ ... Array(activeOrders.length) ].map((_, i) => (
              <div key={i} className={styles.card}>
                <div className="h-2 bg-slate-200 rounded col-span-2 animate-pulse"/>
                <div className="h-4 mt-2.5 w-20 bg-slate-200 rounded col-span-2 animate-pulse"/>
                <div className="h-3 mt-2.5 w-24 bg-slate-200 rounded col-span-2 animate-pulse"/>
              </div>
            ))
          }
        </div>
      </div>
    );
  }

  return (
    <div className="-my-4">
      <div className="inline-block w-full whitespace-nowrap p-4 pr-1 overflow-scroll no-scrollbar">
        {
          orders.map(order => (
            <ActiveOrderCard
              key={order.uuid}
              uuid={order.uuid}
              status={order.status}
              shortId={order.shortId}
            />
          ))
        }
      </div>
    </div>
  )
}
