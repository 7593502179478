import { ExclamationCircleIcon } from '@heroicons/react/20/solid';

export const CartMobileDrawerButton = ({ onClick, icon, title, subtitle, problems }) => {
  return (
    <div className="flex mt-2 gap-3 group" onClick={onClick}>
      { icon }
      <div className="flex flex-1 flex-col">
        <span className="font-medium group-hover:text-blue-600 flex gap-2">
          { title }
          {
            problems &&
            <div className="flex items-center text-orange-500">
              <ExclamationCircleIcon className="w-5 h-5"/>
            </div>
          }
        </span>
        {
          subtitle && <span className="text-gray-500 text-xs leading-5">{ subtitle }</span>
        }
      </div>
    </div>
  )
}
