import { PhoneIcon, ShoppingBagIcon, Square2StackIcon } from '@heroicons/react/24/outline';
import { CategoriesMobileContent } from 'components/categories';
import { CallModal, CartMobileModal, Drawer, SupportModal } from 'components/modals';
import { useCart } from 'query/orders';
import { useState } from 'react';
import { useLocation } from 'react-use';
import { formatPrice } from 'utils';

import { BottomBarButton } from './components/BottomBarButton';


export function BottomBar() {
  const location = useLocation();
  const { isError, isLoading, data } = useCart();
  const [ isCartSheetOpen, setCartSheetOpen ] = useState(false);
  const [ isSupportSheetOpen, setSupportSheetOpen ] = useState(false);
  const [ isCategoriesSheetOpen, setCategoriesSheetOpen ] = useState(false);
  const [ isCallbackSheetOpen, setCallbackSheetOpen ] = useState(false);
  const isMatchLocation = (locationExpression) => locationExpression && location?.pathname?.match(locationExpression);

  return (
    <div className="flex" style={{ marginBottom: 'env(safe-area-inset-bottom)' }}>
      {/*<Link className="contents" prefetch={false} href="/">*/}
      {/*  <BottomBarButton*/}
      {/*    title="Главная"*/}
      {/*    icon={ <HomeIcon/> }*/}
      {/*    isActive={ !isMatchLocation(/^\/.*\/.*$/gi) }*/}
      {/*  />*/}
      {/*</Link>*/}

      <BottomBarButton
        title="Каталог"
        icon={ <Square2StackIcon/> }
        onClick={() => setCategoriesSheetOpen(true)}
      />

      <BottomBarButton
        title={(!isLoading && !isError && data?.price?.subtotal > 0) ? formatPrice(data.price.subtotal) + ' ₽' : 'Корзина'}
        icon={ <ShoppingBagIcon/> }
        onClick={() => setCartSheetOpen(true)}
      />

      <BottomBarButton
        title="Контакты"
        icon={ <PhoneIcon/> }
        onClick={() => setSupportSheetOpen(true)}
      />

      {/* Модалка с категорией */}
      <Drawer
        onClose={() => setCategoriesSheetOpen(false)}
        visible={isCategoriesSheetOpen}
        titleComponent={true}
        title="Категории"
      >
        <CategoriesMobileContent onSelect={() => setCategoriesSheetOpen(false)}/>
      </Drawer>

      {/* Модалка с корзиной */}
      <CartMobileModal
        onClose={() => setCartSheetOpen(false)}
        visible={isCartSheetOpen}
      />

      {/* Модалка с чатом */}
      <Drawer
        onClose={() => setSupportSheetOpen(false)}
        visible={isSupportSheetOpen}
        titleComponent={true}
      >
        <SupportModal
          setIsOpen={setSupportSheetOpen}
          onCallbackRequested={() => setCallbackSheetOpen(true)}
        />
      </Drawer>

      <CallModal isOpen={isCallbackSheetOpen} setIsOpen={setCallbackSheetOpen}/>
    </div>
  )
}
