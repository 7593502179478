import cn from 'classnames';
import Link from 'next/link';
import { ORDER_STATUS } from 'utils/constants';

import styles from './ActiveOrderCard.module.css';


export function ActiveOrderCard({ shortId, uuid, status }) {
  const { title, stage } = ORDER_STATUS[status];

  return (
    <Link
      href={`/orders/${uuid}`}
      className={styles.card}
      prefetch={false}
      // onClick={() => router.push(`/orders/${uuid}`)}
    >
      <div className={styles.bar}>
        {
          [ ...Array(4) ].map((_, i) => (
            <div
              key={i}
              className={cn(styles.barSection, (i < stage) ? 'bg-blue-500' : 'bg-gray-200')}
            >
              {
                i === stage &&
                <div className={styles.barValue}/>
              }
            </div>
          ))
        }
      </div>
      <p className={styles.title}>Заказ { shortId }</p>
      <p className={styles.status}>{ title }</p>
    </Link>
  )
}
