import { CategoriesListItem, CategoryBlock } from 'components/categories';
import { CategoriesContext } from 'context';
import { useContext } from 'react';


export function CategoriesMobileContent({ onSelect }) {
  const { promotedTags, categories } = useContext(CategoriesContext);

  return (
    <ul className="p-4 pt-3 gap-6 pt-6 flex flex-col">
      <div className="flex flex-col gap-2">
        {/*<h4>подборки</h4>*/}
        <div className="font-medium text-green-800 gap-2 flex flex-col">
          { promotedTags.map(({ slug, title }) => <CategoriesListItem onClick={() => onSelect()} isSpecial={true} key={slug} slug={slug} title={title}/>)}
        </div>
      </div>
      <div className="flex flex-col gap-2">
        {/*<h4>категории</h4>*/}
        <div className="grid grid-cols-2 gap-2">
          {
            categories.map(({ title, slug, image }) => <CategoryBlock onClick={() => onSelect()} image={image} key={slug} slug={slug} title={title}/>)
          }
        </div>
      </div>
    </ul>
  )
}
