import { Dialog, Transition } from '@headlessui/react'
import classNames from 'classnames';
import { Drawer } from 'components/modals';
import { useMobileLayout } from 'hooks';
import { Fragment } from 'react';


export function ModalWrapper({ disableHeightLimits, onDismiss, title, keepMounted = true, dragDisabled = false, widthHeader, modalStyle, modalClass, sheetClass, isOpen = false, setIsOpen, children }) {
  const [ isMobileLayout ] = useMobileLayout();

  if (isMobileLayout) {
    return (
      <Drawer
        visible={isOpen}
        onClose={onDismiss}
        titleComponent={true}
        keepMounted={keepMounted}
        disableHeightLimits={disableHeightLimits}
      >
        { children }
      </Drawer>
    )
  }

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        open={isOpen}
        className="relative z-40"
        onClose={() => onDismiss ? onDismiss() : setIsOpen(false)}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-50" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-75"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-125"
            >
              <Dialog.Panel
                style={modalStyle}
                className={
                  classNames(
                    'w-full max-w-7xl transform overflow-hidden rounded-2xl bg-white text-left align-middle shadow-xl transition-all',
                    modalClass
                  )
                }
                // className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all"
              >
                { children }
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  )
}
