import { useEffect, useRef, useState } from 'react';

export const useBoundingClientRect = () => {
  const ref = useRef();
  const [ bbox, setBbox ] = useState({});

  console.log('.', ref)
  const set = () =>
    setBbox(ref && ref.current ? ref.current.getBoundingClientRect() : {});

  useEffect(() => {
    set();
    window.addEventListener('resize', set);
    return () => window.removeEventListener('resize', set);
  }, []);

  return [ bbox, ref ];
};
