import { getRawImage } from './image';


export const createOrganizationLinkedData = ({ hostname, cityName, keywords, description }) => {
  return {
    '@context': 'https://schema.org',
    '@type': 'Organization',
    name: `Флопус — Доставка букетов в ${cityName}`,
    keywords,
    description,
    address: {
      '@type': 'PostalAddress',
      streetAddress: 'Яна Фабрициуса, 15/3',
      addressLocality: 'Сочи',
      addressRegion: 'Краснодарский край',
      // postalCode: "33444",
      addressCountry: 'Россия'
    },
    url: `https://${hostname}`,
    logo: `https://${hostname}/icons/icon-512.png`,
    sameAs: [
      'https://vk.com/flopus.flowers',
      'https://instagram.com/flopus.ru',
      'https://t.me/flopus_flowers',
    ]
  }
}

export const getProductDescriptionByComponents = (product) => {
  return product.components
    .sort((a, b) => b.qty - a.qty)
    .reduce((acc, component, index, initialValue) => {
      if (index > 0) {
        acc.push((initialValue.length === index + 1) ? ' и ' : ', ');
      }

      acc.push(component.qty > 1 ? component.titlePlurals[3] : component.titlePlurals[0]);

      return acc;
    }, [ `${product.category?.componentsTitle || 'Состав'}: ` ])
    .join('')
}

export const createProductLinkedData = (hostname, product) => {
  const imageTemplate = (product.images || []).find(image => image.type === 'image')?.url;

  return {
    '@context': 'http://schema.org',
    '@type': 'Product',
    identifier: product.sku,
    // aggregateRating:{
    //   '@type': 'AggregateRating',
    //   ratingValue: '5.0',
    //   reviewCount: '1'
    // },
    category: {
      '@context': 'https://schema.org/',
      '@type': 'Thing',
      name: product.category.title,
      identifier: product.category.slug,
      url: `https://${hostname}/${product.category.slug}`,
      ... product.category.image && {
        image: getRawImage(product.category.image.url)
      }
    },
    // brand: product.category.title,
    description: product.description || '',
    ... imageTemplate && {
      image: getRawImage(imageTemplate)
    },
    name: product.title,
    offers:{
      '@type': 'Offer',
      url: `https://${hostname}/${product.fullSlug}/`,
      availability: 'http://schema.org/InStock',
      price: Math.round(product.price / 100) || 0,
      priceCurrency: 'RUB'
    },
    sku: product.sku
  };
}

export const createBreadcrumbLinkedData = (hostname, items = []) => {
  return {
    '@context': 'https://schema.org/',
    '@type': 'BreadcrumbList',
    itemListElement: [
      {
        '@type': 'ListItem',
        position: 1,
        name: 'Флопус',
        item: `https://${hostname}`
      },
      ... items.map(({ path, title }, index) => {
        return {
          '@type': 'ListItem',
          position: index + 2,
          name: title,
          item: `https://${hostname}/${path}`
        }
      })
    ]
  }
}
