import ReactInputMask from 'react-input-mask';

import { Input } from './Input';


export function InputMask({ ... props }) {
  return (
    <ReactInputMask { ... props }>
      {
        inputProps => <Input onChange={props.onChange} {... inputProps}/>
      }
    </ReactInputMask>
  )
}
