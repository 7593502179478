import { MODALS } from 'components/modals/constants';
import { ChatPopoverContent } from 'components/popovers/ChatPopover';


export const IDENTIFIER = MODALS.CONTACTS;

export function SupportModal({ isOpen, setIsOpen, onCallbackRequested }) {

  return (
    <div className="relative bg-white p-4 rounded-2xl">
      <ChatPopoverContent
        setIsOpen={ setIsOpen }
        onCallbackRequested={ () => {
          setIsOpen(false);
          onCallbackRequested();
        } }
      />
    </div>
  )
}
