import classNames from 'classnames';
import { Button, LocationSuggester } from 'components/common';
import { MODALS } from 'components/modals/constants';
import { useCartData, useGeocodeData, useMobileLayout } from 'hooks';
import { useReverseGeocode } from 'query';
import { useCart, useUpdateCart } from 'query/orders';
import { useEffect, useState } from 'react';
import Loading from 'react-loading';
import { Map, YMaps, ZoomControl } from 'react-yandex-maps';
import { getAddressString } from 'utils';
import { reachGoal } from 'utils/metrika';


export const IDENTIFIER = MODALS.MAP;

export function MapModal({ onClose }) {
  const { data: orderData } = useCart();
  const { mutate: updateCartMutation } = useUpdateCart();
  const [ cartData, setCartData ] = useCartData();

  const defaultMapState = {
    // center: citiesConfig[city].mapCenter,
    zoom: 15
  };

  const [ isMapLoaded, setMapLoaded ] = useState(false);
  const [ persistent, setPersistent ] = useGeocodeData();
  const [ state, setState ] = useState(persistent?.center ? persistent : defaultMapState);
  const [ input, setInput ] = useState(state?.fullAddress || '');
  const { data: reverseGeocodeResult, isLoading: reverseGeocodeLoading } = useReverseGeocode(
    state.center?.[0],
    state.center?.[1]
  );

  useEffect(() => {
    if (reverseGeocodeResult?.street) {
      const fullAddress = getAddressString(reverseGeocodeResult, false, true);

      setState({
        ... state,
        result: reverseGeocodeResult,
        fullAddress
      });

      if (fullAddress) {
        setInput(fullAddress);
      }
    }
  }, [ reverseGeocodeResult ]);

  const onSelectAddress = (result) => {
    const fullAddress = getAddressString(result, false, true);

    setState({
      ... state,
      ... result.location && {
        center: [ result.location.lat, result.location.lng ],
      },
      result,
      fullAddress
    });

    setInput(fullAddress);
  }

  const onBoundsChange = (e) => {
    const [ lat, lng ] = e.get('newCenter');
    const [ oldLat, oldLng ] = e.get('oldCenter');
    const latChanged = Math.abs(parseFloat(oldLat)) + Math.abs(parseFloat(lat)) > 0.00001;
    const lngChanged = Math.abs(parseFloat(oldLng)) + Math.abs(parseFloat(lng)) > 0.00001;

    if (latChanged || lngChanged) {
      setState({
        ... state,
        center: [ lat, lng ]
      });
    }
  }

  const saveDeliveryPreferences = () => {
    if (state.result?.location?.lat && orderData?.uuid) {
      const { city, flat, house, street } = state.result;

      updateCartMutation({
        uuid: orderData.uuid,
        city,
        street,
        houseNumber: house,
        apartment: flat,
        location: {
          lat: +state.result.location.lat,
          lng: +state.result.location.lng
        }
      });
    }

    reachGoal('delivery_location_updated', {
      source: 'map',
      address: getAddressString(state.result)
    });

    setCartData({
      ... cartData,
      house: state.result.house,
      street: state.result.street,
      streetType: state.result.streetType,
      flat: state.result.flat,
      address: getAddressString(state.result, true, true)
    });

    setPersistent(state);
    onClose();
  }

  const isDisabled = !state.result?.street || reverseGeocodeLoading;
  const [ isMobileLayout ] = useMobileLayout();

  return (
    <div className="w-full h-auto">
      <div
        className={
          classNames(isMobileLayout && 'px-5 pt-5')
        }
      >
        <h3 className="mb-3">Выберите адрес доставки</h3>
        <div className="text-gray-500 leading-7 mt-2">Уточнить адрес и квартиру можно при&nbsp;оформлении&nbsp;заказа</div>
        <div className="flex flex-col sm:flex-row gap-2 mt-4">
          <div className="w-full">
            <LocationSuggester
              zIndex={4000}
              value={input}
              onChange={(e) => setInput(e.target.value)}
              name="address"
              withoutCity={true}
              onSelect={(result, stringResult) => onSelectAddress(result, stringResult)}
            />
          </div>
          <Button
            aria-label="Выбрать адрес доставки"
            disabled={isDisabled}
            onClick={saveDeliveryPreferences}
            color={isDisabled ? 'gray' : 'blue' }
            sx="transition-colors rounded-2xl relative"
          >
            <span
              style={{
                opacity: reverseGeocodeLoading ? '0' : '1',
                ... isDisabled && {
                  color: 'gray'
                }
              }}
            >
              Доставить сюда
            </span>
            {
              (reverseGeocodeLoading) && <Loading className="absolute" height={20} width={20} color="black" type="spin"/>
            }
          </Button>
        </div>
      </div>
      <div
        style={{ height: '300px' }}
        className={
          classNames(
            'overflow-hidden mt-4 relative',
            !isMobileLayout && 'rounded-xl'
          )
        }
      >
        <img
          style={{
            transform: 'translate(-50%,-88%)',
            zIndex: 3001,
            userSelect: 'none'
          }}
          className="absolute left-1/2 top-1/2 pointer-events-none"
          src={'/icons/marker.svg'}
          alt=""
        />
        {
          !isMapLoaded ? <div style={{ height: '300px' }} className="w-full bg-gray-100"/> : null
        }
        <YMaps>
          <Map
            width="100%"
            height="300px"
            state={state}
            onLoad={() => setMapLoaded(true)}
            onBoundsChange={onBoundsChange}
          >
            <ZoomControl float="right" options={{ float: 'right' }}/>
          </Map>
        </YMaps>
      </div>
    </div>
  )
}
