import cn from 'classnames';
import { CategoryBlock } from 'components/categories';
import { Button } from 'components/common';
import { ProductCard } from 'components/products';
import { SearchContext } from 'context';
import { useRouter } from 'next/router';
import { useGlobalSearch } from 'query';
import { useContext, useEffect, useState } from 'react';
import { useDebounce, useWindowSize } from 'react-use';
import { getNoun } from 'utils';

import styles from './QuickSearchResults.module.css';


export function QuickSearchResults({ close }) {
  const router = useRouter();
  const { width = 1024 } = useWindowSize();
  const { searchQuery, isSearchVisible } = useContext(SearchContext);
  const [ debouncedQuery, setDebouncedQuery ] = useState('');
  const [ layout, setLayout ] = useState('desktop');

  useDebounce(
    () => {
      setDebouncedQuery(searchQuery);
    },
    300,
    [ searchQuery ]
  );

  const { data, isLoading, isFetching } = useGlobalSearch(debouncedQuery);

  const products = data?.find(i => i.type === 'products');
  const categories = data?.find(i => i.type === 'categories');

  useEffect(() => {
    if (width < 768) {
      setLayout('mobile');
    } else {
      setLayout('desktop');
    }
  }, [ width ]);

  if (!isSearchVisible) {
    return null;
  }

  return (
    <>
      <div className={cn(isLoading && 'opacity-70', styles.wrapper)}>
        {
          (products?.items?.length < 1 && categories?.items?.length < 1 && layout === 'desktop') &&
            <div className={styles.empty}>По этому запросу ничего не найдено</div>
        }
        {
          products?.items &&
            <div className={styles.groups}>
              {/*<h2 className="mb-4">каталог</h2>*/}
              <div className={cn(products.items.length > 3 ? 'lg:grid-cols-4' : 'lg:grid-cols-3', 'grid grid-cols-2  gap-4')}>
                {
                  products.items.map(p => (
                    <ProductCard
                      key={p.slug}
                      slug={p.slug}
                      onClick={() => close()}
                      product={{
                        ...p,
                        images: [ p.image ]
                      }}
                    />
                  ))
                }
              </div>
            </div>
        }
        {
          categories?.items &&
            <div className={ styles.categoriesBlock }>
              {/*<h2>категории</h2>*/}
              <div className={ styles.categoryList }>
                {
                  categories.items.map(c =>
                    <div key={c.id} className="shadow-sm rounded-xl border-gray-100">
                      <CategoryBlock
                        image={c.image}
                        title={c.title}
                        slug={c.slug}
                        onClick={close}
                      />
                    </div>
                  )
                }
              </div>
            </div>
        }

        {
          (searchQuery.length > 0 && products?.count > 5 && layout === 'mobile') &&
            <div className={ styles.resultsPageButton_mobile }>
              <Button
                className="w-full flex gap-1.5"
                color="blue"
                disabled={isLoading}
                onClick={async () => {
                  await router.push(`/search?query=${encodeURI(searchQuery)}`);
                  close();
                }}
              >
                Посмотреть ещё {products.count} {getNoun(products.count, 'товар', 'товара', 'товаров')}
              </Button>
            </div>
        }
      </div>
    </>
  )
}
