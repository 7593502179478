import { CheckIcon } from '@heroicons/react/20/solid';
import cn from 'classnames';
import { HoverPopover } from 'components/common';
import { Drawer } from 'components/modals';
import PropTypes from 'prop-types';
import { cloneElement, useState } from 'react';
import { useWindowSize } from 'react-use';

import styles from './SelectPopover.module.css';


const SelectPopoverContent = ({ selectedValue, values, onChange }) => {
  const handleChange = (key) => {
    if (selectedValue === key) {
      return;
    }

    onChange(key);
  }

  return (
    <div className={styles.popover}>
      {
        values.map(({ key, title }) => {
          const isActive = key === selectedValue;

          return (
            <div
              key={key}
              className={ cn(styles.item, isActive && 'font-medium text-blue-600') }
              onClick={ () => handleChange(key) }
            >
              { isActive ? <CheckIcon className="w-5 h-5"/> : <div className="w-5"/> }
              { title }
            </div>
          );
        })
      }
    </div>
  )
};

export function SelectPopover({ values, children, selectedValue, onChange }) {
  const [ isDrawerVisible, setDrawerVisible ] = useState(false);
  const { width = 1024 } = useWindowSize();

  if (width < 768) {
    return (
      <>
        {
          cloneElement(children, {
            onClick: () => setDrawerVisible(true)
          })
        }
        <Drawer
          visible={isDrawerVisible}
          onClose={() => setDrawerVisible(false)}
        >
          <div className="mt-4">
            <SelectPopoverContent
              values={values}
              selectedValue={selectedValue}
              onChange={value => {
                setDrawerVisible(false);

                if (onChange) {
                  onChange(value);
                }
              }}
            />
          </div>
        </Drawer>
      </>
    );
  }

  return (
    <HoverPopover
      placement="bottom-end"
      offsetTop={8}
      offsetLeft={2}
      target={children}
    >
      <SelectPopoverContent
        values={values}
        selectedValue={selectedValue}
        onChange={onChange}
      />
    </HoverPopover>
  )
}

SelectPopover.propTypes = {
  values: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
    })
  ),
  selectedValue: PropTypes.string,
};
