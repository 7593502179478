import { BreadcrumbLinkedData, OrganizationLinkedData } from 'components/json-ld';
import { CitiesContext, HostContext } from 'context';
import Head from 'next/head';
import { useContext } from 'react';


export function PageMeta({ isCustomTitle, title, keywords, breadcrumbs = [], description, path = '', preview, children }) {
  const hostname = useContext(HostContext);
  const { currentCity } = useContext(CitiesContext);

  if (!isCustomTitle && title && path.length) {
    breadcrumbs.push({ title, path });
  }

  const pageTitle = title
    ? (isCustomTitle ? title : `${title} — ${currentCity.seoTitle}`)
    : currentCity.seoTitle;

  const fullDescription = description ? `${description}. ${currentCity.seoDescription}` : currentCity.seoDescription;

  return (
    <Head>
      <title>{pageTitle}</title>
      <meta name="description" content={fullDescription} />
      <meta property="og:type" content="website"/>
      <meta property="og:title" content={pageTitle}/>
      <meta property="og:description" content={fullDescription}/>
      <meta property="og:site_name" content="флопус"/>
      <meta property="og:url" content={`https://${hostname}/${path}`}/>
      <meta property="og:image" content={ preview || '/icons/icon-192.png' }/>
      <meta name="viewport" content="minimum-scale=1, initial-scale=1, shrink-to-fit=no, user-scalable=no, viewport-fit=cover"/>
      <meta name="apple-mobile-web-app-title" content="флопус"/>
      <meta name="apple-mobile-web-app-capable" content="yes"/>
      <meta name="apple-mobile-web-app-status-bar-style" content="white-transparent"/>
      <meta name="keywords" content={keywords || currentCity.seoKeywords} />
      <meta name="theme-color" content="#FFFFFF"/>
      {/*<link rel="android-touch-icon" sizes="180x180" href="/android-touch-icon.png"/>*/}
      {/*<link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png"/>*/}
      {/*<link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png"/>*/}
      <link rel="apple-touch-icon" href={'/icons/icon-144.png'} />
      <link rel="manifest" href={'/manifest.json'}/>
      <link rel="mask-icon" href={'/icons/icon-192.png'} color="white"/>
      <link rel="icon" type="image/png" href={'/favicon.png'}/>
      { currentCity.yandexVerification && <meta name="yandex-verification" content={currentCity.yandexVerification}/> }
      { children }
      <OrganizationLinkedData currentCity={currentCity} hostname={hostname}/>
      <BreadcrumbLinkedData hostname={hostname} items={breadcrumbs}/>
    </Head>
  )
}
