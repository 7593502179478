import classNames from 'classnames';
import { forwardRef } from 'react';

import styles from './Button.module.css';


const getHoverStyle = (color) => {
  switch (color) {
    case 'gray':
      return 'hover:bg-gray-200/30';
    case 'white':
      return 'hover:bg-gray-50';
    case 'shadow-white':
      return 'hover:bg-gray-50';
    default:
      return 'hover:opacity-90';
  }
}

const Button = forwardRef(({ className = '', type = 'button', children, style, color, disabled, size = 'md', sx = '', ...props }, ref) => {
  return (
    <button
      ref={ref}
      type={type}
      disabled={disabled}
      style={{ ... style }}
      { ...props }
      className={
        classNames(
          className,
          styles.base,
          styles[size],
          disabled ? 'bg-gray-200 opacity-90' : [ getHoverStyle(color), styles[color], 'cursor-pointer' ],
          sx
        )
      }
    >
      { children }
    </button>
  )
});

Button.displayName = 'Button';

export {
  Button
};
