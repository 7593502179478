export const LegalContacts = ({ className }) => {
  return (
    <div>
      <p>Индивидуальный предприниматель Стрелец М. Л. (ИНН 732817261234, ОГРН 322730000008965)</p>
      <p>
        Находимся по адресу <a target="_blank" href="https://yandex.ru/maps/org/127701727792" className="link-ext">Сочи, улица Яна Фабрициуса, 15/3</a>
      </p>
      <p>
        Письма можно писать сюда → <a href="mailto:hello@flopus.ru" className="link-ext">hello@flopus.ru</a>
      </p>
    </div>
  )
}
