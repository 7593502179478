import { forwardRef } from 'react';
import { resizeTemplatedImage } from 'utils';

const ResponsiveImage = forwardRef(({ src, width, height, size, lazy, defaultSize, breakpoints, alt = 'Изображение', className, ...rest }, ref) => {
  const sizes = Object.entries(breakpoints).sort((a, b) => b[0] - a[0]);

  return (
    <picture ref={ref} className={className}>
      {
        sizes.map(([ mediaQuery, size ]) => {
          return <source
            width={size.toString()}
            height={size.toString()}
            key={mediaQuery}
            media={`(min-width: ${mediaQuery}px)`}
            srcSet={resizeTemplatedImage(src, size, size)}
          />
        })
      }

      <img
        src={resizeTemplatedImage(src, defaultSize, defaultSize)}
        alt={alt}
        style={{ width: 'auto' }}
        { ... lazy && {
          loading: 'lazy'
        } }
      />
    </picture>
  )
});

ResponsiveImage.displayName = 'ResponsiveImage';

export {
  ResponsiveImage,
};
