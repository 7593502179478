import { useClickOutside, useDebouncedState } from '@react-hookz/web';
import { useGlobalSearch } from 'query';
import { useEffect, useRef, useState } from 'react';
import { useWindowSize } from 'react-use';

import { QuickSearchContainer } from './QuickSearchContainer';
import { QuickSearchResults } from './QuickSearchResults';
import { SearchInput } from './SearchInput';


export function QuickSearch({ isVisible, setVisible }) {
  const wrapperRef = useRef(null);
  const { width = 1024 } = useWindowSize();
  const [ inputValue, setInputValue ] = useState('');
  const [ query, setQuery ] = useDebouncedState(inputValue, 800, 1000);
  const { data, isLoading, isFetching } = useGlobalSearch(query);
  const [ layout, setLayout ] = useState('desktop');


  const categories = data?.find(i => i.type === 'categories');
  const products = data?.find(i => i.type === 'products');


  // на десктопе показываем контент если: есть фокус на инпуте
  // на мобиле показываем контент если: в пропсах isMobileSearchActive = true

  useClickOutside(wrapperRef, () => {
    if (layout === 'desktop') {
      setVisible(false);
    }
  });

  // useEffect(() => setGlobalSearchVisible(inputValue.length > 0 && isVisible && data?.length > 1), [ inputValue, data, isVisible ]);
  // useEffect(() => setGlobalSearchHasResults(isVisible && data?.length > 0), [ data ]);



  useEffect(() => {
    if (width < 768) {
      setLayout('mobile');
    } else {
      setLayout('desktop');
    }
  }, [ width ]);

  return (
    <div className="hidden md:block w-full max-w-screen-lg pr-16" ref={wrapperRef}>
      {/*<pre>{ JSON.stringify(layout) }{ JSON.stringify(isVisible) }</pre>*/}
      <SearchInput
        className="md:ml-11 hidden md:flex"
        // inputClassName="text-center"
        onChange={value => {
          setInputValue(value);
          setQuery(value);
        }}
        value={inputValue}
        onFocus={() => setVisible(true)}
      />

      <QuickSearchContainer
        layout={layout}
        value={inputValue}
        isVisible={(layout === 'mobile' && isVisible || layout === 'desktop' && Array.isArray(data) && isVisible)}
        setVisible={setVisible}
        onChange={(value) => {
          setInputValue(value);
          setQuery(value);
        }}
      >
        <QuickSearchResults
          query={query}
          layout={layout}
          isLoading={isFetching || isLoading}
          products={products}
          categories={categories}
          close={() => setVisible(false)}
        />
      </QuickSearchContainer>
    </div>
  )
}

