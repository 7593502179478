import { createBreadcrumbLinkedData } from 'utils';

export function BreadcrumbLinkedData({ hostname, items, ... props }) {
  return (
    <script
      { ...props }
      type="application/ld+json"
      dangerouslySetInnerHTML={{
        __html: JSON.stringify(
          createBreadcrumbLinkedData(hostname, items)
        )
      }}
    />
  )
}
