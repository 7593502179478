import APIService from './api.service';


class ReviewsService extends APIService {
  async getMany(params = { skip: 0, count: 10 }) {
    return this.api.get('/reviews', {
      params
    });
  }
}


export default new ReviewsService();
