import '../globals.css';
import '../../node_modules/@yandex/ui/Drawer/Drawer.css';
import 'swiper/css';

import { useSessionStorageValue } from '@react-hookz/web';
import cn from 'classnames';
import { Footer, HeaderV2 } from 'components/layout';
import { ProductModal } from 'components/modals';
import { CategoriesContext, CitiesContext, HostContext, SearchContext } from 'context';
import { usePWA } from 'hooks';
import App from 'next/app'
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router'
import Script from 'next/script'
import { useEffect, useState } from 'react';
import { Toaster } from 'react-hot-toast';
import { QueryClient, QueryClientProvider } from 'react-query';
import { CitiesService, OrdersService, ProductsService } from 'services/api';
import { getCityFromHostname } from 'utils';

const BottomBar = dynamic(() => import('components/layout').then(module => module.BottomBar), {
  ssr: false,
});

const ChatPopover = dynamic(() => import('components/popovers').then(module => module.ChatPopover), {
  ssr: false,
});


const queryClient = new QueryClient();
const Products = new ProductsService();
const Orders = new OrdersService();

MyApp.getInitialProps = async (context) => {
  const hostname = typeof window !== 'undefined'
    ? window.location.hostname
    : (context.ctx.req.headers['x-forwarded-host'] || context.ctx.req.headers.host);

  let activeOrderIds = context.ctx.req?.cookies?.flopus_active_orders;

  try {
    activeOrderIds = JSON.parse(activeOrderIds);
  } catch {
    activeOrderIds = [];
  }

  const [ appProps, promotedTags, categories, cities, activeOrders ] = await Promise.all([
    App.getInitialProps(context),
    Products.getPromotedTags(),
    Products.getCategories(),
    CitiesService.getMany(),
    Orders.getOrderStatuses(activeOrderIds)
  ]);

  // console.dir({ categories, promotedTags }, { depth: null });

  return {
    ... appProps,
    pageProps: {
      ... appProps.pageProps,
      categories: categories.items,
      promotedTags,
      activeOrders: activeOrders,
      cities,
      hostname
    }
  };
}

function MyApp({ Component, pageProps }) {
  const { bodyClassList, simpleLayout } = Component;
  const { hostname } = pageProps;
  const isPWA = usePWA();
  const [ searchQuery, setSearchQuery ] = useState('');
  const [ isSearchVisible, setSearchVisible ] = useState(false);
  const city = getCityFromHostname(hostname);
  const router = useRouter();

  const { set: setHistoryState } = useSessionStorageValue('flopus_history_state', 'empty');

  useEffect(() => {
    router.events.on('routeChangeStart', () => setHistoryState('exists'));
    router.events.off('routeChangeStart', () => setHistoryState('exists'));
  });

  return (
    <HostContext.Provider value={hostname}>
      <CitiesContext.Provider value={{
        cities: pageProps.cities,
        currentCity: pageProps.cities.find(({ slug }) => slug === city)
      }}>
        <CategoriesContext.Provider value={{
          categories: pageProps.categories,
          promotedTags: pageProps.promotedTags
        }}>
          <QueryClientProvider client={queryClient}>
            <SearchContext.Provider value={{
              searchQuery,
              setSearchQuery,
              isSearchVisible,
              setSearchVisible
            }}>
              <Toaster containerStyle={{ bottom: 90 }}/>
              {
                !simpleLayout &&
                  <HeaderV2/>
              }
              <div className={cn(bodyClassList, 'flex-1 md:pt-8 relative flex justify-center')}>
                <div className="relative max-w-screen-2xl w-full flex flex-col overflow-hidden">
                  <div className="flex-1">
                    <Component {...pageProps}/>
                  </div>
                  {
                    (!simpleLayout && !isPWA) && <Footer/>
                  }
                  {
                    isPWA && <div className="h-20"/>
                  }
                  {
                    !isPWA &&
                      <div className="absolute w-16 h-16 bottom-0 right-4 3xl:-right-24 3xl:bottom-8">
                        <div className="hidden md:block fixed bottom-4 3xl:bottom-8 z-20">
                          <ChatPopover/>
                        </div>
                      </div>
                  }
                </div>

                <div
                  style={{ boxShadow: '0 -8px 6px -6px rgba(0, 0, 0, 0.05)' }}
                  className="md:hidden fixed w-full -bottom-0.5 pb-0.5 px-4 z-10 bg-white backdrop-blur-md bg-opacity-95"
                >
                  <BottomBar/>
                </div>
              </div>

              <ProductModal/>

              <Script
                id="metrika"
                strategy="afterInteractive"
                dangerouslySetInnerHTML={{
                  __html: `(function(m,e,t,r,i,k,a){m[i]=m[i]||function(){(m[i].a=m[i].a||[]).push(arguments)};
                       m[i].l=1*new Date();k=e.createElement(t),a=e.getElementsByTagName(t)[0],k.async=1,k.src=r,a.parentNode.insertBefore(k,a)})
                       (window, document, "script", "https://mc.yandex.ru/metrika/tag.js", "ym");
                    
                       ym(92458157, "init", {
                            clickmap:true,
                            trackLinks:true,
                            accurateTrackBounce:true,
                            webvisor:true
                       });`
                }}
              />
              <Script
                id="vk_pixel"
                strategy="afterInteractive"
                dangerouslySetInnerHTML={{
                  __html: `
                    !function(){var t=document.createElement("script");t.type="text/javascript",t.async=!0,t.src='https://vk.com/js/api/openapi.js?169',t.onload=function(){VK.Retargeting.Init("VK-RTRG-1677809-bG2Tf"),VK.Retargeting.Hit()},document.head.appendChild(t)}();
                  `
                }}
              />
              <Script
                id="mrg_pixel"
                strategy="afterInteractive"
                dangerouslySetInnerHTML={{
                  __html: `
                    var _tmr = window._tmr || (window._tmr = []);
                    _tmr.push({id: "3290755", type: "pageView", start: (new Date()).getTime()});
                    (function (d, w, id) {
                      if (d.getElementById(id)) return;
                      var ts = d.createElement("script"); ts.type = "text/javascript"; ts.async = true; ts.id = id;
                      ts.src = "https://top-fwz1.mail.ru/js/code.js";
                      var f = function () {var s = d.getElementsByTagName("script")[0]; s.parentNode.insertBefore(ts, s);};
                      if (w.opera == "[object Opera]") { d.addEventListener("DOMContentLoaded", f, false); } else { f(); }
                    })(document, window, "tmr-code");
                  `
                }}
              />

              <Script src={'/js/utm.js'} strategy="afterInteractive"/>
              <Script src={'/js/chat.js'} strategy="lazyOnload"/>

            </SearchContext.Provider>
          </QueryClientProvider>
        </CategoriesContext.Provider>
      </CitiesContext.Provider>
    </HostContext.Provider>

  )
}

export default MyApp;
