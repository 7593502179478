import Link from 'next/link';
import { sendContactClickEvent } from 'services/api/utils.service';
import { reachGoal } from 'utils/metrika';

import styles from './Footer.module.css';

export function Footer() {
  return (
    <footer className="pb-20 md:pb-12 md:mt-12">
      <nav className={styles.container}>
        <div className={styles.leftCol}>
          <Link aria-label="О нас" className="link" prefetch={false} href={'/about'}>
            о нас
          </Link>
          <Link aria-label="Информация о доставке" className="link" prefetch={false} href={'/delivery'}>
            информация о доставке
          </Link>
          <Link aria-label="Отзывы" className="link" prefetch={false} href={'/reviews'}>
            отзывы
          </Link>
          {/*<Link prefetch={false} href="/faq">*/}
          {/*  <a aria-label="Частые вопросы" className="link">частые вопросы</a>*/}
          {/*</Link>*/}
          {/*<Link prefetch={false} href="/party">*/}
          {/*  <a aria-label="Оформление праздников" className="link">*/}
          {/*    оформление праздников&nbsp;*/}
          {/*    <Emoji size={4}>1f389</Emoji>*/}
          {/*  </a>*/}
          {/*</Link>*/}
          {/*<Link prefetch={false} href="/faq">*/}
          {/*  <a aria-label="Букет на заказ" className="link">*/}
          {/*    букет на заказ&nbsp;*/}
          {/*    <Emoji size={4}>2728</Emoji>*/}
          {/*  </a>*/}
          {/*</Link>*/}
        </div>
        <div className={styles.middleCol}>
          <Link aria-label="Пользовательское соглашение" className="link" prefetch={false} href={'/policy'}>
            пользовательское соглашение
          </Link>

          <Link aria-label="Политика возврата" className="link" prefetch={false} href={'/terms'}>
            условия оплаты и возврата
          </Link>
        </div>
        <div className={styles.rightCol}>
          <a
            className="link"
            aria-label="Позвонить нам"
            href={`tel:${process.env.NEXT_PUBLIC_PHONE_NUMBER}`}
            onClick={() => {
              sendContactClickEvent('ph', 'footer');
              reachGoal('contact_click', { type: 'phone' });
            }}
          >
            { process.env.NEXT_PUBLIC_FORMATTED_PHONE_NUMBER }
          </a>
          {/*<a*/}
          {/*  target="_blank"*/}
          {/*  aria-label="Наш Instagram"*/}
          {/*  href="https://instagram.com/flopus.ru"*/}
          {/*  className="link" rel="noreferrer"*/}
          {/*>*/}
          {/*  <span className={styles.instagram}>наш инстаграм</span>*/}
          {/*</a>*/}
          <a
            target="_blank"
            aria-label="Мы VK"
            href="https://vk.com/flopus.flowers"
            className="link text-blue-500" rel="noreferrer"
          >
            мы вконтакте
          </a>
          {/*<div className="pt-3 text-xs text-gray-500">*/}
          {/*  флопус&nbsp;*/}
          {/*  <Emoji size={3}>2764-fe0f</Emoji>*/}
          {/*  &nbsp;2021*/}
          {/*</div>*/}
        </div>
      </nav>
      {/*{*/}
      {/*  (Object.keys(utm).some(key => key.startsWith('utm_'))) ?*/}
      {/*    (*/}
      {/*      <div className={styles.providers}>*/}
      {/*        <img height="32" width="280" alt="" src="/payment-systems.svg"/>*/}
      {/*      </div>*/}
      {/*    ) : (*/}
      {/*      <div className="h-16"/>*/}
      {/*    )*/}
      {/*}*/}
      <div className="flex items-center pt-6">
        <div className="w-full md:ml-8 md:w-fit text-center">
          <a target="_blank" href="https://yandex.ru/maps/org/127701727792" className="link-ext">Сочи, улица Яна Фабрициуса, 15/3</a>
        </div>
        {/* <div className={styles.providers}>
          <img draggable={false} height="23" width="285" alt="" src={'/payment-systems.svg'}/>
        </div> */}
      </div>
    </footer>
  )
}
