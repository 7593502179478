import classNames from 'classnames';

export function Emoji({ children, size, className }) {
  const sizeMap = {
    3: 'h-3 w-3',
    4: 'h-4 w-4',
    6: 'h-6 w-6',
    7: 'h-7 w-7',
    10: 'h-12 w-12',
    default: 'h-5 w-5'
  };

  const src = `/_next/image?url=/e/${children}.png&${size >= 6 ? 'w=96&h=96' : 'w=48&h=48'}&q=90`;
  // const src = `https://twemoji.maxcdn.com/v/13.1.0/svg/${children}.svg`;

  return (
    <span
      style={{ backgroundImage: `url(${src})` }}
      className={classNames(
        sizeMap[size] || sizeMap.default,
        'inline-block bg-cover',
        className
      )}
    />
  )
}
