import { CategoriesListItem, CategoryBlock } from 'components/categories';
import { CategoriesContext } from 'context';
import { useContext } from 'react';

import styles from '../CategoriesListItem/CategoriesListItem.module.css';


export function CategoriesDesktopContent({ onSelect }) {
  const { promotedTags, categories } = useContext(CategoriesContext);

  return (
    <ul className="flex md:flex-row">
      <div className="flex flex-col gap-4">
        {/*<h4>подборки</h4>*/}
        <div className="flex flex-col gap-3 font-medium text-green-700">
          {
            promotedTags.map(({ slug, title }) => (
              <CategoriesListItem
                className="w-64"
                isSpecial={true}
                key={slug}
                slug={slug}
                title={title}
                onClick={onSelect}
              />
            ))
          }
        </div>
      </div>
      <div className="pl-5 flex flex-col gap-4">
        {/*<h4>категории</h4>*/}
        <div className={styles.container}>
          {
            categories.map(({ title, slug, image }) => <CategoryBlock
              onClick={onSelect}
              className="w-52 hover:opacity-80 transition-all"
              key={slug}
              image={image}
              slug={slug}
              title={title}
            />)
          }
        </div>
      </div>
    </ul>
  )
}

