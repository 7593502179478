import { XMarkIcon } from '@heroicons/react/24/outline';
import { Drawer as BaseDrawer } from '@yandex/ui/Drawer/touch-phone';


export function Drawer({ hideCloseButton, disableHeightLimits, children, visible, onClose, keepMounted = true, dragDisabled = false }) {
  return (
    <BaseDrawer
      visible={visible}
      onClose={onClose}
      view="default"
      titleComponent={true}
      keepMounted={keepMounted}
      {
        ...!disableHeightLimits && {
          maxSize: 'calc(100% - 66px)'
        }
      }
      dragDisabled={dragDisabled}
    >
      <div className="relative">
        {
          (!hideCloseButton && onClose) &&
          <div className="group p-2 pt-3 top-0 right-0 fixed cursor-pointer z-20">
            <div onClick={() => onClose()} className="transition-all p-2 group-hover:bg-gray-200 rounded-full">
              <XMarkIcon className="w-6 h-6 text-black opacity-70"/>
            </div>
          </div>
        }
        { children }
      </div>
    </BaseDrawer>
  )
}
