import { Button, Emoji, FormError, Input, InputPhone } from 'components/common';
import { MODALS } from 'components/modals/constants';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { useState } from 'react';
import { sendForm } from 'services/api/utils.service';
import { reachGoal } from 'utils/metrika';
import * as yup from 'yup';

const schema = yup.object().shape({
  name: yup.string().required('Укажите своё имя').default(''),
  friendName: yup.string().required('Укажите имя друга').default(''),
  friendPhone: yup.string().required('Укажите телефон друга').matches(/^\+\d \d{3} \d{3} \d{4}$/, 'Введите полный номер телефона').default('')
});


export const IDENTIFIER = MODALS.HINT;

export function HintModal({ product }) {
  const [ submittedName, setSubmittedName ] = useState('');

  return (
    <div className="rounded-t-2xl">
      {
        submittedName &&
        <div className="absolute h-full w-full bg-white z-20 rounded-2xl flex items-center justify-center flex-col p-10">
          <img src={'/e/1f44c.png'} alt="" width="100px"/>
          <p className="mt-6 text-lg">{ submittedName } получит намёк о подарке!</p>
        </div>
      }
      <div style={{ backgroundImage: 'url(/hint.jpg)' }} className="h-56 relative rounded-t-2xl bg-cover bg-center"/>
      <div className="mt-2 p-6 pt-4">
        <p className="text-gray-800">Понравился букет и вы хотели бы получить его в подарок от близкого человека?</p>
        <br/>
        <p className="text-gray-800">
          Заполните его имя и номер телефона, мы отправим сообщение с намёком!
          <Emoji className="ml-1 -mb-0.5">1f609</Emoji>
        </p>
        <Formik
          initialValues={{
            name: '',
            friendName: '',
            friendPhone: ''
          }}
          validationSchema={schema}
          validateOnChange={true}
          validateOnBlur={true}
          onSubmit={async ({ name, friendName, friendPhone }) => {
            reachGoal('wish_applied', {
              product_id: product.id,
              title: product.title,
              phone_number: friendPhone
            });

            await sendForm('wish', {
              productId: product.id,
              senderName: name,
              friendName,
              friendPhone
            });

            setSubmittedName(friendName);
          }}
        >
          <Form className="flex flex-col gap-3 mt-5">
            <div>
              <Field as={Input} name="name" placeholder="Ваше имя"/>
              <ErrorMessage name="name" component={FormError}/>
            </div>

            <div>
              <Field as={Input} name="friendName" placeholder="Имя друга"/>
              <ErrorMessage name="friendName" component={FormError}/>
            </div>

            <div>
              <InputPhone name="friendPhone" placeholder="Номер телефона друга"/>
              <ErrorMessage name="friendPhone" component={FormError}/>
            </div>

            <Button className="mt-3" size="lg" color="blue" type="submit">Отправить</Button>
          </Form>
        </Formik>
      </div>
    </div>
  )
}
