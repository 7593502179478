import APIService from './api.service';

export class StoriesService extends APIService {
  constructor(params) {
    super(params);
  }
  
  async getMany() {
    return this.api.get('/stories');
  }
}
