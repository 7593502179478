import { Listbox } from '@headlessui/react'
import { ChevronRightIcon } from '@heroicons/react/24/outline';
import classNames from 'classnames';
import { cloneElement, useState } from 'react'
import { ENABLED_PAYMENT_METHODS as paymentMethods } from 'utils/constants';

import styles from './PaymentMethodSelect.module.css';


export function PaymentMethodSelect({ value, onChange, className }) {
  const paymentMethod = paymentMethods.find(({ id }) => id === value) || paymentMethods[0];
  const [ selectedValue, setSelectedValue ] = useState(paymentMethod);

  return (
    <Listbox
      value={selectedValue}
      onChange={(value) => {
        setSelectedValue(value);
        
        if (onChange) {
          onChange(value.id);
        }
      }}
    >
      <div className="relative">
        <Listbox.Button className={classNames('group', styles.btn, className)}>
          <div className="flex gap-3">
            { cloneElement(selectedValue.icon) }
            <p>{ selectedValue.name }</p>
          </div>
          <ChevronRightIcon className="w-5 h-5 text-gray-400 group-hover:text-gray-500"/>
        </Listbox.Button>
        <Listbox.Options>
          <div className={ styles.items }>
            {
              paymentMethods.filter(method => method.id !== selectedValue.id)
                .map((method) => (
                  <Listbox.Option
                    key={method.id}
                    value={method}
                  >
                    <div className={ styles.item }>
                      { method.icon && cloneElement(method.icon) }
                      { method.name }
                    </div>
                  </Listbox.Option>
                ))
            }
          </div>
        </Listbox.Options>
      </div>
    </Listbox>
  )
}
