import cn from 'classnames';

export const BlockDivider = ({ className }) => {
  return (
    <div className={cn(className, 'md:hidden flex flex-col bg-gray-100')}>
      <div className="bg-white w-full h-5 rounded-b-full"/>
      <div className="w-full h-2"/>
      <div className="bg-white w-full h-5 rounded-t-full"/>
    </div>
  )
}
