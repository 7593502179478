import APIService from './api.service';

class GeocodeService extends APIService {
  constructor() {
    super();
  }

  async geocode(query, cityId) {
    const items = await this.api.get('/addresses/geocode', {
      params: {
        query,
        cityId,
        count: 4
      }
    });
    //
    // return items.reduce((acc, item) => {
    //   if (!item.street || acc.find(({ street, houseNumber }) => street === item.street)) {
    //     return acc;
    //   }
    //
    //   return acc;
    // }, []);
    return items.filter(({ street }) => !!street);
  }

  async reverseGeocode(lat, lng) {
    return this.api.get('/addresses/reverseGeocode', {
      params: {
        lat,
        lng
      }
    });
  }
}

export default new GeocodeService();
