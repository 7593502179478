import { Dialog } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/solid';
import { MODALS } from 'components/modals/constants';
import { SlideIndicator } from 'components/modals/StoryModal/components/SlideIndicator';
import { useMobileLayout } from 'hooks';
import { useEffect, useState } from 'react'
import { useWindowSize } from 'react-use';
import { resizeTemplatedImage } from 'utils/image';


export const IDENTIFIER = MODALS.STORY;

export function StoryModal({ stories, currentStoryId, onClose }) {
  const [ currentSlide, setCurrentSlide ] = useState(0);
  const [ slideProgress, setSlideProgress ] = useState(0);
  const { width: windowWidth, height: windowHeight } = useWindowSize();
  const [ isMobileLayout ] = useMobileLayout();

  const currentStory = stories.find(({ id }) => id === currentStoryId);
  const switchSlide = (offset) => {
    const newSlideId = currentSlide + offset;

    if (newSlideId >= 0 && newSlideId < currentStory.slides.length) {
      setCurrentSlide(currentSlide + offset);
      setSlideProgress(0);
    } else {
      onClose();
    }
  }

  const maxHeight = windowHeight - 90;
  const height = maxHeight > 800 ? 800 : maxHeight;
  const width = Math.round(0.5625 * height);

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (slideProgress < 100) {
        setSlideProgress(slideProgress + 2.5);
      } else {
        switchSlide(1);
      }
    }, 250);

    return () => {
      clearTimeout(timeout);
    }
  }, [ slideProgress ]);

  if (!currentStory) return null;

  return (
    <Dialog open={!!currentStoryId} onClose={onClose}>
      <div className="flex items-center justify-center min-h-screen">
        <Dialog.Overlay className="fixed z-30 inset-0 bg-black opacity-90" />
        <div style={{ position: 'fixed' }} className="z-30 flex items-center absolute h-full top-0 mx-auto">
          {/* Slide */}
          <div className="flex flex-col items-center">
            <div
              className="bg-black bg-opacity-40 relative bg-cover rounded-2xl"
              style={{
                width,
                height,
                backgroundImage: `url(${resizeTemplatedImage(currentStory.slides[currentSlide].url, width, height)})`
              }}
            >
              {/* Close Button */}
              <div
                onClick={() => onClose()}
                className="absolute cursor-pointer transition-opacity z-10 top-8 right-3 p-2 bg-white bg-opacity-10 hover:bg-opacity-30 backdrop-blur-md rounded-3xl"
              >
                <XMarkIcon className="w-6 h-6 text-white opacity-40"/>
              </div>
              {/* Previous Slide Button */}
              {
                currentSlide !== 0 &&
                <div
                  onClick={() => currentSlide !== 0 && switchSlide(-1)}
                  className="absolute group left-0 top-0 h-full w-1/2 cursor-pointer"
                >
                  <div className="transition-opacity rounded-l-2xl opacity-0 group-hover:opacity-100 w-20 h-full bg-gradient-to-r from-black/20 via-black-10 to-transparent"/>
                </div>
              }
              {/* Next Slide Button */}
              {
                currentSlide !== currentStory.slides.length - 1 &&
                <div
                  onClick={() => switchSlide(1)}
                  className="absolute group right-0 top-0 h-full w-1/2 cursor-pointer"
                >
                  <div className="transition-opacity rounded-r-2xl absolute right-0 opacity-0 group-hover:opacity-100 w-24 h-full bg-gradient-to-l from-black/20 via-black-10 to-transparent"/>
                </div>
              }

              <div className="absolute flex gap-2 p-4 w-full">
                {
                  currentStory.slides.map((slide, index) => {
                    let progress = 0;

                    if (index === currentSlide) {
                      progress = slideProgress;
                    } else if (index < currentSlide) {
                      progress = 100;
                    }

                    return <SlideIndicator key={index} progress={progress}/>
                  })
                }
              </div>
            </div>
            {
              !isMobileLayout &&
              <div className="flex justify-center" rel="noreferrer">
                <a
                  href="https://instagram.com/flopus.ru"
                  target="_blank"
                  className="w-fit backdrop-blur-md text-white/70 text-sm items-center m-3 p-2 pr-4 bg-white bg-opacity-10 hover:bg-opacity-20 transition-opacity rounded-xl flex gap-2 " rel="noreferrer"
                >
                  <img src={'/instagram-icon.png'} width="24" alt=""/>
                  Больше историй у нас в инстаграме
                </a>
              </div>
            }
          </div>
        </div>

      </div>
    </Dialog>
  )
}
