import { RadioGroup } from '@headlessui/react'
import cn from 'classnames';
import { DolyameSchedule } from 'components/dolyame';
import { cloneElement } from 'react';
import { formatPrice } from 'utils';
import { ENABLED_PAYMENT_METHODS as enabledPaymentMethods } from 'utils/constants';


export function PaymentMethodSelectDesktop({ name, totalPrice, value, onChange, className }) {
  // const isPaymentMethodAvailable = (paymentMethod) => getPaymentMethodConfig()

  return (
    <RadioGroup
      className="divide-y divide-gray-100"
      // value={field.value}
      // onChange={value => {
      //   field.onChange({
      //     target: {
      //       value,
      //       name
      //     }
      //   })
      value={value}
      onChange={value => onChange(value)}
    >
      {
        enabledPaymentMethods
          .filter(({ minimumPrice }) => (!minimumPrice || minimumPrice < totalPrice))
          .map(({ id, icon, name, minimumPrice, maximumPrice }) => {
            const isExceedingPrice = maximumPrice && maximumPrice < totalPrice;
            const isInsufficientPrice = minimumPrice && minimumPrice > totalPrice;
            const isPaymentMethodAvailable = !isExceedingPrice && !isInsufficientPrice;
            
            return (
              <RadioGroup.Option disabled={isExceedingPrice || isInsufficientPrice} key={id} value={id}>
                {({ checked }) => (
                  <div className="flex py-5 group cursor-pointer flex-row gap-4 cursor-pointer ">
                    <div>
                      <span
                        className="bg-white border-gray-300 mt-0.5 h-7 w-7 shrink-0 rounded-full border flex items-center justify-center"
                        aria-hidden="true"
                      >
                        <span className={cn('rounded-full w-4 h-4', checked ? 'bg-blue-600' : 'group-hover:bg-gray-100')} />
                      </span>
                    </div>
                    <div className="flex flex-col gap-4 w-full">
                      <div className="flex w-full items-center">
                        <div className={cn(value === id ? 'text-blue-600' : 'text-gray-800', 'mt-1 flex-1 font-semibold w-full flex flex-col gap-4')}>
                          { name }
                        </div>
                        <div>
                          { icon && cloneElement(icon) }
                        </div>
                      </div>
                      {
                        (isExceedingPrice) &&
                        <div className="flex flex-col gap-6 -mt-2.5">
                          <p className="text-gray-500">Доступно для заказов до {formatPrice(maximumPrice)} ₽</p>
                        </div>
                      }
                      {
                        (id === 'dolyame' && value === 'dolyame') &&
                        <div className="flex flex-col gap-6">
                          <p className="text-gray-500">Оплатите 25% от стомости покупки, а оставшиеся 3 части спишутся автоматически с шагом в две недели. Без процентов и комиссий, как обычная оплата картой.</p>
                          <DolyameSchedule price={totalPrice}/>
                        </div>
                      }
                    </div>

                  </div>
                )}
              </RadioGroup.Option>
            )
          })
      }
    </RadioGroup>
  )
}
