export function ChatPopoverCountBadge({ children }) {
  return (
    <div
      style={{ minWidth: '20px' }}
      className="h-5 text-white text-xs flex justify-center items-center rounded-3xl bg-red-500"
    >
      { children }
    </div>
  )
}
