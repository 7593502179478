export default [
  {
    preview: 'https://img.flopus.ru/czM6Ly9mbG9wdXMvd2lkZ2V0cy9zcGVjaWFsLmpwZw',
    title: 'Авторские',
    altTitle: 'Авторские букеты',
    slug: '/special',
    size: 'lg',
  },
  {
    preview: 'https://img.flopus.ru/czM6Ly9mbG9wdXMvd2lkZ2V0cy9zcGVjaWFsLmpwZw',
    title: 'Авторские',
    altTitle: 'Авторские букеты',
    slug: '/special',
    size: 'lg',
  },
  // {
  //   preview: 'https://img.flopus.ru/czM6Ly9mbG9wdXMvd2lkZ2V0cy9pd2QuanBn',
  //   title: 'Весенняя коллекция',
  //   altTitle: 'Весенняя коллекция',
  //   slug: '/spring',
  //   size: 'md',
  // },
  {
    preview: 'https://img.flopus.ru/czM6Ly9mbG9wdXMvd2lkZ2V0cy9yb3Nlcy5qcGc',
    title: 'Розы',
    altTitle: 'Букеты из роз',
    slug: '/roses',
    size: 'md',
  },
  {
    preview: 'https://img.flopus.ru/czM6Ly9mbG9wdXMvd2lkZ2V0cy9ib3hlcy5qcGc',
    title: 'Коробки',
    altTitle: 'Коробки с цветами',
    slug: '/boxes',
    size: 'sm',
  },
  {
    preview: 'https://img.flopus.ru/czM6Ly9mbG9wdXMvd2lkZ2V0cy9iYXNrZXRzLmpwZw=',
    title: 'Корзины',
    altTitle: 'Корзины с цветами',
    slug: '/baskets',
    size: 'sm',
  },
  {
    preview: 'https://img.flopus.ru/czM6Ly9mbG9wdXMvd2lkZ2V0cy9zd2VldHMuanBn',
    title: 'Сладости',
    altTitle: 'Сладости',
    slug: '/sweets',
    size: 'sm',
  },
];
