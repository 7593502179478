import { Dialog, Transition } from '@headlessui/react';
import { MagnifyingGlassIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { useClickOutside } from '@react-hookz/web';
import cn from 'classnames';
import { CategoriesDesktopContent } from 'components/categories';
import { CityPopover } from 'components/popovers';
import { CitiesContext } from 'context';
import { domAnimation, LazyMotion, m } from 'framer-motion';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useCart } from 'query/orders';
import { cloneElement, useContext, useEffect, useRef, useState } from 'react';
import { useWindowScroll, useWindowSize } from 'react-use';
import { sendContactClickEvent } from 'services/api/utils.service';

import { CartButton, HeaderCatalogButton, Logo, NavigationButton, QuickSearch } from './components';
import styles from './Header.module.css';


export function HeaderV2() {
  const router = useRouter();
  const { y } = useWindowScroll();
  const { currentCity } = useContext(CitiesContext);
  const { width } = useWindowSize();
  const [ dropDownSection, _setDropDownSection ] = useState(null);
  const { data } = useCart();
  const [ isCartModalShown, setCartModalShown ] = useState(false);

  const setDropDownSection = (section) => {
    _setDropDownSection(section === dropDownSection ? null : section);
  }


  const dropDownSections = {
    search: <QuickSearch/>,
    categories: <CategoriesDesktopContent onSelect={() => _setDropDownSection(null)}/>
  }


  const [ layout, setLayout ] = useState('desktop');
  const [ isScrolled, setIsScrolled ] = useState(false);
  const [ isScrolledMore, setIsScrolledMore ] = useState(false);
  const [ isScrolledOnDesktop, setScrolledOnDesktop ] = useState(false);

  useEffect(() => {
    if (width < 768) {
      setLayout('mobile');
    } else {
      setLayout('desktop');
    }

    setIsScrolled(y > 44);
    setIsScrolledMore(y > 114);
    setScrolledOnDesktop(layout === 'desktop' && isScrolled);
  }, [ isScrolledOnDesktop, isScrolled, layout, width, y ]);

  return (
    <>
      <div className="w-full flex justify-center py-2 bg-white z-30">
        <div className="hidden md:flex items-center max-w-screen-2xl w-full font-medium rounded-b-2xl p-2 px-6 mt-1  justify-between">
          <div className="items-center flex flex-row gap-6">
            <HeaderCatalogButton
              isOpen={dropDownSection === 'categories'}
              onClick={() => setDropDownSection('categories')}
            />
            <Link
              href={'/about'}
              prefetch={false}
              className={styles.link}
            >
              О нас
            </Link>
            <Link
              href={'/delivery'}
              prefetch={false}
              className={styles.link}
            >
              Доставка
            </Link>
            <Link
              href={'/reviews'}
              prefetch={false}
              className={styles.link}
            >
              Отзывы
            </Link>
            {/*<Link*/}
            {/*  href={'/iwd'}*/}
            {/*  prefetch={false}*/}
            {/*  className="text-pink-600 hover:text-pink-500"*/}
            {/*>*/}
            {/*  Авторские*/}
            {/*</Link>*/}
          </div>
          <div className="flex items-center gap-6">
            <a
              className="hover:text-blue-700"
              href={`tel:${process.env.NEXT_PUBLIC_PHONE_NUMBER}`}
              onClick={() => sendContactClickEvent('ph', 'header')}
            >
              { process.env.NEXT_PUBLIC_FORMATTED_PHONE_NUMBER }
            </a>
            <CityPopover
              selectedValue={currentCity?.slug}
            >
              <span
                className="text-blue-700 hover:text-blue-500 cursor-pointer"
              >
                {currentCity?.title}
              </span>
            </CityPopover>
          </div>
        </div>
      </div>
      <div
        className={cn(
          (layout === 'mobile')
            ? 'backdrop-blur-md bg-opacity-95'
            : ((!dropDownSection && isScrolledMore) ? styles.scrollShadow : ''),
          'transition-all bg-white w-full z-20 sticky top-0 flex justify-center'
        )}
        style={{
          ... (isScrolled && layout === 'mobile') && {
            boxShadow: '0 8px 6px -6px rgba(0, 0, 0, 0.05)'
          }
        }}
      >
        <div className="relative px-4 md:px-6 flex flex-row items-center gap-4 max-w-screen-2xl w-full border-gray-100 justify-between">
          <div className="flex justify-center gap-3 items-center h-20">
            <LazyMotion features={domAnimation}>
              <m.div
                layoutId="catalog"
                initial={{ opacity: 1 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                {
                  ... (!isScrolledOnDesktop) && {
                    className: 'hidden'
                  }
                }
              >
                <HeaderCatalogButton
                  onClick={() => setDropDownSection('categories')}
                  isOpen={dropDownSection === 'categories'}
                  large={true}
                />
              </m.div>
            </LazyMotion>

            <div className="absolute w-fit left-6">
              <div className={cn((isScrolledOnDesktop) && 'hidden', 'transition-all flex items-center gap-4')}>
                <Logo size={56} className="my-2"/>
                <div>

                  <h1 className="md:text-lg text-base font-medium">
                    флопус
                    <CityPopover
                      selectedValue={currentCity.slug}
                    >
                      <span
                        className="md:hidden text-blue-700 hover:text-blue-500 cursor-pointer"
                      >
                         в {currentCity.titleIn}
                      </span>
                    </CityPopover>

                  </h1>
                  <div className="text-sm md:text-base text-gray-600">
                    доставка цветов и подарков
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="flex items-center">
            <NavigationButton onClick={() => setDropDownSection('search')}>
              {
                dropDownSection === 'search'
                  ? <XMarkIcon className="w-8 h-8"/>
                  : <MagnifyingGlassIcon className="w-8 h-8"/>
              }
            </NavigationButton>
            <NavigationButton
              className="hidden md:block"
              onClick={() => {
                if (data?.items?.length > 0) {
                  router.push('/cart');
                }
              }}
            >
              <CartButton/>
            </NavigationButton>
            {/*<CartDesktopModal*/}
            {/*  isOpen={isCartModalShown}*/}
            {/*  onClose={() => setCartModalShown(false)}*/}
            {/*/>*/}
          </div>
        </div>
      </div>

      <div className={cn('h-4')}/>
      <DropDownMenu
        layout={layout}
        open={dropDownSection !== null}
        onClose={() => _setDropDownSection(null)}
      >
        {/*<p>{ dropDownSection }</p>*/}
        { dropDownSections[dropDownSection] }
      </DropDownMenu>
    </>
  )
}


const DropDownMenu = ({ open, layout, children, onClose }) => {
  const wrapperRef = useRef(null);

  useClickOutside(wrapperRef, (e) => {
    if (layout === 'desktop' && !e.target.dataset?.dropdown) {
      onClose();
    }
  }, [ 'touchstart', 'mouseup' ]);

  if (!open) {
    return null;
  }

  if (layout === 'mobile') {
    return (
      <div ref={wrapperRef}>
        <Dialog open={open} onClose={onClose} className="fixed bg-white top-0 z-30 w-screen h-screen">
          { cloneElement(children, { onClose, layout }) }
        </Dialog>
      </div>
    )
  }

  return (
    <Transition className="bg-white sticky absolute top-20 z-20 w-full" show={open}>
      <div ref={wrapperRef} className="p-10 shadow-xl shadow-black/5 flex justify-center">
        <div className="max-w-screen-2xl w-full px-6">
          { cloneElement(children, { onClose, layout }) }
        </div>
      </div>
    </Transition>
  )
}
