import { CallModal } from 'components/modals';
import { useState } from 'react';
import { sendContactClickEvent } from 'services/api/utils.service';
import { reachGoal } from 'utils/metrika';

export const ProductContactsBlock = () => {
  const [ isModalOpen, setModalOpen ] = useState(false);

  return (
    <div className="flex lg:col-span-2 justify-between gap-6 items-end">
      <div className="rounded-3xl">
        <div className="p-5 border rounded-3xl rounded-br-none space-y-2">
          <p>
            Мы на связи {' '}
            <a
              href={`tel:${process.env.NEXT_PUBLIC_PHONE_NUMBER}`}
              onClick={() => sendContactClickEvent('ph', 'product')}
              className="text-blue-700 link-ext font-medium whitespace-nowrap"
            >
              { process.env.NEXT_PUBLIC_FORMATTED_PHONE_NUMBER }
            </a>
          </p>
          <p>
            Будем рады ответить на любые вопросы, помочь с выбором или собрать похожий букет
          </p>
        </div>

        <div className="p-4 flex gap-2 justify-between">
          <div className="flex gap-0.5 md:gap-1.5">
            <a
              target="_blank"
              rel="noreferrer"
              className="hover:opacity-80 flex m-1 w-10 h-10 p-1 rounded-xl bg-gradient-to-tl from-green-500 to-green-400"
              href={`https://wa.me/${process.env.NEXT_PUBLIC_PHONE_NUMBER.replace(/^8/, '7')}`}
              onClick={() => sendContactClickEvent('wa', 'product')}
            >
              <img src={'/product/whatsapp.svg'}/>
            </a>
            <a
              href="https://t.me/flopus_flowers"
              target="_blank"
              rel="noreferrer"
              onClick={ () => reachGoal('contact_click', { type: 'telegram' }) }
              className="hover:opacity-80 flex m-1 w-10 h-10 p-1.5 rounded-xl bg-gradient-to-tl from-blue-500 to-blue-400"
            >
              <img className="fill-white -ml-0.5" src={'/product/telegram.svg'}/>
            </a>
          </div>
          <button
            onClick={() => setModalOpen(true)}
            className="hover:bg-blue-50 whitespace-nowrap text-sm md:text-base border flex rounded-2xl px-4 items-center font-medium text-blue-600"
          >
            Перезвоните мне
          </button>
          <CallModal
            setIsOpen={setModalOpen}
            isOpen={isModalOpen}
          />
        </div>

      </div>

      <img className="z-10" height="112px" width="89px" src={'/product/hmm.png'}/>
    </div>
  )
}
