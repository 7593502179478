import cn from 'classnames';
import { useCartData } from 'hooks';


const PRESETS = [
  {
    title: 'Только переписка',
    text: 'Отвечаю только в переписке.'
  },
  {
    title: 'Уточнить адрес',
    text: 'Уточнить адрес у получателя.'
  },
  {
    title: 'Нет домофона',
    text: 'На адресе нет домофона.'
  },
  {
    title: 'Ресепшен',
    text: 'Оставить заказ на ресепшене.'
  }
];

export const CartMobileComment = () => {
  const [ localCartData, setLocalCartData, setLocalCartDataKey ] = useCartData();
  const currentValue = localCartData?.comment || '';
  const trimValue = (text) => text.replace(/\s+/g, ' ');

  const PresetButton = ({ title, text }) => {
    const isActive = localCartData.comment?.includes(text);

    return (
      <div
        className={cn(isActive ? 'bg-blue-500 text-white' : 'bg-gray-100', 'px-3 py-1 rounded-full')}
        onClick={() => {
          if (!isActive) {
            setLocalCartDataKey('comment', trimValue(currentValue + ' ' + text))
          } else {
            setLocalCartDataKey('comment', trimValue(currentValue.replace(text, '')))
          }
        }}
      >
        { title }
      </div>
    )
  }

  return (
    <div className="flex flex-col gap-3 px-4 pb-4">
      <div className="-mx-4 px-4 text-sm flex gap-3 whitespace-nowrap overflow-x-scroll no-scrollbar">
        {
          PRESETS.map(({ title, text }, index) =>
            <PresetButton key={index} title={title} text={text}/>
          )
        }
      </div>
      <textarea
        rows={2}
        onChange={(e) => setLocalCartDataKey('comment', trimValue(e.target.value))}
        value={currentValue}
        className="w-full rounded-xl border-gray-200 outline-none"
        placeholder="Примечание курьеру и флористу"
      />
    </div>
  )
};
