import cn from 'classnames';

export const CartMobileBottomBar = ({ className, children }) => {
  return (
    <div
      className={cn(className, 'fixed bottom-0 w-full bg-white rounded-t-2xl flex flex-col')}
      style={{
        paddingBottom: 'env(safe-area-inset-bottom)',
        boxShadow: '0px 0px 12px 2px #00000014'
      }}
    >
      { children }
    </div>
  );
}
