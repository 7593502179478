import cn from 'classnames';
import Link from 'next/link';

import categories from '../showcaseCategories';
import styles from './CategoriesShowcaseMobile.module.css';


export function CategoriesShowcaseMobile() {
  return (
    <div className={cn(styles.container, 'no-scrollbar')}>
      {/* Авторские */}
      {
        categories.map(category => (
          <Link
            key={category.slug}
            prefetch={false}
            href={category.slug}
            aria-label={category.altTitle}
            className={styles.item}
          >
            <div
              style={{ backgroundImage: `url(${category.preview})` }}
              className={styles.image}
            />
            <p className={styles.title}>{ category.title }</p>
          </Link>
        ))
      }
    </div>
  )
}
