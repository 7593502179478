import { Dialog as HeadlessDialog, Transition } from '@headlessui/react'
import classNames from 'classnames';
import { Fragment } from 'react';


export function Dialog({ isOpen = false, setIsOpen, children, className }) {
  return (
    <Transition appear show={isOpen} as={Fragment}>
      <HeadlessDialog
        as="div"
        open={isOpen}
        className="z-50 fixed inset-0 z-10 overflow-y-auto"
        onClose={() => setIsOpen(false)}
      >
        <HeadlessDialog.Overlay className="fixed inset-0 bg-black opacity-60" />

        <div className="min-h-screen text-center md:px-4">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <HeadlessDialog.Overlay className="fixed inset-0" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="inline-block h-screen align-middle"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-75"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-125"
          >
            <div
              className={
                classNames(
                  className,
                  'absolute left-0 bottom-0 md:relative inline-block text-left max-w-5xl transition-all transform bg-white shadow-xl rounded-t-2xl md:rounded-b-2xl',
                )
              }
            >
              { children }
            </div>
          </Transition.Child>
        </div>
      </HeadlessDialog>
    </Transition>
  )
}
