export const getRequestHostname = (req) => (req.headers['x-forwarded-host'] || req.headers.host);

export const getCityFromHostname = (hostname = '') => {
  const subdomain = (hostname.match(/^[a-z0-9]+\.[a-z0-9]+\.[a-z]+$/) && hostname.split('.')?.[0]);

  return (subdomain && subdomain !== 'www') ? subdomain : 'sochi';
};

export const getRequestPaginationData = (query, pageSize = 30) => {
  console.log('getRequestPaginationData', query)
  const page = +query.page > 0 ? +query.page : 1;

  return {
    pageNumber: page,
    pageSize,
    skip: (page - 1) * pageSize,
    count: pageSize
  };
}


