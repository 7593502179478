import classNames from 'classnames';
import React from 'react';

import { Price } from './Price';

export const ProductPrice = ({ className, product }) => {
  return (
    <div className="flex items-end">
      <Price
        className={
          classNames(
            className,
            'font-medium ',
            product.discountPrice && 'text-red-600'
          )
        }
        price={product.discountPrice ? product.discountPrice : product.price}
      />
      {
        product.discountPrice &&
        <Price className={classNames(className, 'pb-1 ml-2 text-base')} isCrossed={true} price={product.price}/>
      }
    </div>
  )
}
