import { ArrowUpRightIcon } from '@heroicons/react/24/solid';
import cn from 'classnames';
import Link from 'next/link';

import categories from '../showcaseCategories';
import styles from './CategoriesShowcaseDesktop.module.css';


export function CategoriesShowcaseDesktop() {
  return (
    <div className="hidden xl:block">

      <div className={cn(styles.container, 'no-scrollbar')}>
        {/* Авторские */}
        {
          categories.map(category => (
            <Link
              key={category.slug}
              prefetch={false}
              href={category.slug}
              aria-label={category.altTitle}
              style={{ backgroundImage: `url(${category.preview})` }}
              className={cn(styles.card, styles[`card_${category.size}`], 'rounded-lg group')}
            >
              {/*<div className={cn(styles.badge, 'lg:')}>*/}
              <div className={cn(styles.badge, styles[`badge_${category.size}`], 'group-hover:bg-opacity-60')}>
                { category.title }
                <ArrowUpRightIcon className={styles.icon}/>
              </div>
              {/*</div>*/}
            </Link>
          ))
        }
      </div>
    </div>
  )
}
