import { ArrowRightIcon } from '@heroicons/react/24/solid';
import { useWindowSize } from '@react-hookz/web';
import cn from 'classnames';
import { ProductCard } from 'components/products';
import { useTouchDevice } from 'hooks';
import Link from 'next/link';
import { useRef, useState } from 'react';
import { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import styles from './CategoryCarousel.module.css';

export function CategoryCarousel({ index, category, products, bookmarks }) {
  const { width: windowWidth } = useWindowSize();
  const COLS_COUNT = (windowWidth >= 768) ? 3 : 2;
  const prevRef = useRef(null);
  const nextRef = useRef(null);
  const [ currentSlide, setCurrentSlide ] = useState(0);
  const isTouchDevice = useTouchDevice();

  return (
    <section className="relative mt-8 md:mt-12">
      <div className={styles.container}>
        <Link
          prefetch={false}
          href={`/${category.slug}`}
          aria-label={category.title}
          className={styles.title}
        >
          <h2 className={styles.titleLink}>{ category.title.toLowerCase() }</h2>
          <span
            className="md:hidden w-6 h-4 bg-contain bg-no-repeat"
            style={{ backgroundImage: 'url(/icons/right-arrow.svg)' }}
          />
        </Link>

        <div className="hidden md:flex absolute right-4">
          <div ref={prevRef} className={cn(currentSlide === 0 ? 'opacity-40' : 'hover:text-blue-600', styles.move)}>
            <div className="w-8 h-6 bg-no-repeat" style={{ backgroundImage: 'url(/icons/left-arrow.svg)' }}/>
          </div>
          <div ref={nextRef} className={cn(currentSlide >= products.length - COLS_COUNT ? 'opacity-50' :  'hover:text-blue-600', styles.move)}>
            <div className="w-8 h-6 bg-no-repeat" style={{ backgroundImage: 'url(/icons/right-arrow.svg)' }}/>
          </div>
        </div>
      </div>
      <Swiper
        key={category.slug}
        wrapperTag="ul"
        freeMode={true}
        onInit={(swiper) => {
          if (!isTouchDevice) {
            swiper.params.navigation.prevEl = prevRef.current;
            swiper.params.navigation.nextEl = nextRef.current;
            swiper.navigation.init();
            swiper.navigation.update();
          }
        }}
        slidesPerGroup={2}
        onRealIndexChange={swiper => setCurrentSlide(swiper.realIndex)}
        spaceBetween={10}
        modules={isTouchDevice ? [] : [ Navigation ]}
        slidesPerView={2.1}
        breakpoints={{
          768: {
            slidesPerView: 4,
            slidesPerGroup: 3,
            spaceBetween: 16
          },
        }}
      >
        {
          products.map((product, slideIndex) =>
            <SwiperSlide tag="li" key={product.id}>
              <ProductCard
                lazy={index > 1 || slideIndex > 4}
                style={{ flexShrink: '0' }}
                product={product}
                size={COLS_COUNT >= 3 ? 600 : 400}
                slug={product.fullSlug}
                addToBookmarks={bookmarks.add}
                removeFromBookmarks={bookmarks.remove}
                isBookmarked={bookmarks.isBookmarked(product.id)}
              />
            </SwiperSlide>
          )
        }
        <SwiperSlide tag="li">
          <div className="flex h-full w-full flex-col">
            <Link
              prefetch={false}
              aria-label={category.title}
              href={`/${category.slug}`}
              style={{ paddingBottom: '100%' }}
              className="relative overflow-hidden rounded-lg"
            >
              <div className={cn('flex group flex-col gap-4 items-center justify-center', styles.categorySlide)}>
                <div className="w-14 h-14 flex bg-gray-200/70 rounded-full justify-center items-center">
                  <ArrowRightIcon className="w-7 h-7"/>
                </div>
                <p className="group-hover:text-blue-600 font-medium text-sm">Показать все</p>
              </div>
            </Link>
          </div>
        </SwiperSlide>
      </Swiper>
    </section>
  )
}
