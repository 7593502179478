import APIService from './api.service';


export class DeliveryAreasService extends APIService {
  constructor(params) {
    super(params);
  }

  async getDeliveryAreas() {
    const response = await this.api.get('/deliveryAreas?count=100');

    // if (globalThis['deliveryAreas']) {
    //   return globalThis['deliveryAreas'];
    // }
    //
    // if (!response?.items) {
    //   return [];
    // }

    const structuredData = response.items.map(({ id, title, conditions, polygon }) => {
      return {
        id,
        title,
        conditions,
        polygon: [ polygon.map(([ lat, lon ]) => [ lon, lat ]) ]
      }
    });

    // globalThis['deliveryAreas'] = structuredData;

    return structuredData;
  }
}
