export const PAYMENT_METHODS = [
  {
    id: 'sbp',
    name: 'Система быстрых платежей',
    shortName: 'СБП онлайн',
    params: {
      paymentMethod: 'online',
      paymentProvider: 'nspk'
    },
    submitStatus: 'checkout',
    icon: (
      <div className="w-8 h-6 justify-center flex">
        <div className="bg-contain h-6" style={{ width: '19px', backgroundImage: 'url(/icons/sbp.svg)' }} />
      </div>
    )
  },
  {
    id: 'card',
    name: 'Картой онлайн',
    shortName: 'Картой онлайн',
    params: {
      paymentMethod: 'online',
      paymentProvider: 'paykeeper',
    },
    submitStatus: 'checkout',
    icon: (
      <div className="h-6 justify-center items-center flex">
        <div className="bg-contain h-5" style={{ width: '144px', backgroundImage: 'url(/icons/card-providers.svg)' }} />
      </div>
    )
  },
  {
    id: 'dolyame',
    name: 'Долями',
    shortName: 'Долями',
    minimumPrice: 250000,
    maximumPrice: 3000000,
    params: {
      paymentMethod: 'online',
      paymentProvider: 'dolyame',
    },
    submitStatus: 'checkout',
    icon: (
      <div className="w-8 h-6 justify-center items-center flex">
        <div className="bg-contain h-5" style={{ width: '23px', fill: 'black', backgroundImage: 'url(/dolyame/logo_s.svg)' }} />
      </div>
    ),
    agreements: [
      {
        title: 'условиями сервиса «Долями»',
        url: 'https://static.dolyame.ru/static/dolyame/dolyame_contract.pdf'
      }
    ]
  },
  {
    id: 'card_transfer',
    name: 'Банковский перевод',
    shortName: 'Банковский перевод',
    params: {
      paymentMethod: 'card_transfer'
    },
    submitStatus: 'created',
  },
  {
    id: 'cash_indoor',
    name: 'Наличными при получении',
    shortName: 'При получении',
    params: {
      paymentMethod: 'cash_indoor'
    },
    submitStatus: 'created',
    icon: (
      <div className="w-8 h-6">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="url(#grad2)">
          <defs>
            <linearGradient id="grad2" x2="1" y2="1">
              <stop offset="0%" stopColor="#16a34a" />
              <stop offset="100%" stopColor="#15803d" />
            </linearGradient>
          </defs>
          <path strokeLinecap="round" strokeLinejoin="round" d="M17 9V7a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2m2 4h10a2 2 0 002-2v-6a2 2 0 00-2-2H9a2 2 0 00-2 2v6a2 2 0 002 2zm7-5a2 2 0 11-4 0 2 2 0 014 0z" />
        </svg>
      </div>
    )
  }
];

export const getPaymentMethodConfig = (paymentMethodId) => PAYMENT_METHODS.find(({ id }) => id === paymentMethodId);

export const ENABLED_PAYMENT_METHOD_IDS = (process.env.NEXT_PUBLIC_ENABLED_PAYMENT_METHODS || '')
  .split(',')
  .filter(method => !!method);

export const ENABLED_PAYMENT_METHODS = PAYMENT_METHODS.filter(({ id }) => ENABLED_PAYMENT_METHOD_IDS.includes(id));

export const ORDER_STATUS = {
  draft: {
    title: 'Ещё не отправлен',
    stage: 0,
  },
  checkout: {
    title: 'Ожидает оплаты',
    stage: 0
  },
  created: {
    title: 'Оплачен',
    color: 'green',
    description: 'Мы получили Ваш заказ, свяжемся с Вами в течение пары минут',
    stage: 1,
  },
  accepted: {
    title: 'Принят',
    color: 'green',
    description: 'Заказ передан флористу, совсем скоро он начнёт его собирать',
    stage: 1,
  },
  ready: {
    title: 'Завершили сборку',
    color: 'green',
    description: 'Мы уже собрали Ваш заказ и скоро передадим его курьеру',
    stage: 2
  },
  shipped: {
    title: 'Доставляем',
    color: 'green',
    description: 'Заказ уже у курьера, оповестим Вас после его доставки',
    stage: 3
  },
  completed: {
    title: 'Завершён',
    color: 'green',
    stage: 4
  },
  declined: {
    title: 'Отменён'
  },
  canceled: {
    title: 'Отменён'
  },
  undefined: {
    title: 'Обрабатывается',
    stage: 2
  }
};
