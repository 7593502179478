import { create } from 'zustand';


export const useModalsStore = create((set, get) => ({
  visibleModals: new Set(),
  isModalVisible: (identifier) => get().visibleModals.has(identifier),
  setModalVisible: (identifier, visible) => set((currentState) => {
    const visibleModals = visible
      ? get().visibleModals.add(identifier)
      : get().visibleModals.delete(identifier);

    return {
      ... currentState,
      visibleModals
    }
  }),
}));

// product
// back() pushToStack() close()

export const useProductModal = create((set, get) => ({
  isVisible: false,
  isLoading: false,
  stack: [],
  product: () => {
    const stack = get().stack;

    return stack[stack.length - 1];
  },
  back: () => {
    const currentStack = get().stack;
    const shiftedStack = (currentStack.length > 1) ? currentStack.shift() : [];
    const newStack = get().isLoading ? currentStack : shiftedStack;


    console.log('back:', 'current stack ->', currentStack, 'new', newStack);

    return set(state => ({
      ... state,
      stack: newStack,
      isVisible: Boolean(newStack.length > 0),
      isLoading: false
    }));
  },
  setVisible: () => set(state => ({
    ... state,
    isVisible: true
  })),
  close: () => set(state => ({
    ... state,
    stack: [],
    isLoading: false,
    isVisible: false
  })),
  pushToStack: (value) => set(state => ({
    ... state,
    stack: [
      ... state.stack,
      value
    ],
    isVisible: true,
    isLoading: false
  })),
  setLoading: (value = true) => set(state => ({
    ... state,
    isLoading: value,
    isVisible: true
  }))
}));
