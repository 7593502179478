import { resizeTemplatedImage } from './image';

export const getProductPreview = (product, size = 100) => {
  const preview = product.images?.find(image => image.type === 'image');
  const previewURL = preview && resizeTemplatedImage(preview.url, size, size);

  return previewURL || 'https://img.flopus.ru/czM6Ly9mbG9wdXMvcHJvZHVjdC1nYXAuanBn';
}

export const isEligibleForDolyame = (product) => {
  const price = product.discountPrice || product.price;

  return price > 250000 && price < 3000000;
}
